import { motion, AnimateSharedLayout } from "framer-motion"
import { Button } from "components"
import styled from "styled-components"

const DatasetPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  // margin-bottom: 1rem;

  & > span {
    font-size: 1.65rem;
    display: flex;
    align-items: center;
    transform: translateX(-1rem);
    margin-bottom: 1rem;
  }

  & ul.buttons {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & li {
      margin: 1rem;
      position: relative;
    }
  }

  & .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    left: -20px;
    background: var(--color-accent);
  }
`
const StyledButton = styled((props) => <Button {...props} />)<{ $isActive?: boolean }>`
  color: white !important;
  & span {
    white-space: inherit !important;
  }
`

interface IDatasetPicker {
  title?: string
  selected: string
  optionsMapping: Record<string, string>
  setSelected: (sel: string) => void
}
export default function DatasetPicker({
  title = "Pick A Dataset:",
  selected,
  optionsMapping,
  setSelected,
}: IDatasetPicker) {
  return (
    <DatasetPickerWrapper>
      <span>{title}</span>
      <AnimateSharedLayout>
        <ul className="buttons">
          {Object.keys(optionsMapping).map((key: string) => (
            <motion.li key={key} className={selected === key ? "selected" : ""}>
              <StyledButton
                buttonType={"primary"}
                $isActive={selected === key}
                key={key}
                onClick={() => setSelected(key)}
              >
                {optionsMapping[key]}
              </StyledButton>
              {selected === key && <motion.div className="dot" layoutId="underline" />}
            </motion.li>
          ))}
        </ul>
      </AnimateSharedLayout>
    </DatasetPickerWrapper>
  )
}
