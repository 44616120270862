import { useState, Fragment } from "react"

import { format as d3Format } from "d3-format"
import { timeFormat as d3TimeFormat } from "d3-time-format"
import { min as d3Min, max as d3Max, group as d3Group } from "d3-array"
import { scaleTime as d3ScaleTime, scaleLinear as d3ScaleLinear } from "d3-scale"
import { stack as d3Stack } from "d3-shape"
import { useChartDimensions } from "hooks/charts/useChartDimensions"
import { Axis, Chart, Circles, Legend, Line, Tooltip } from "visualizations"
import { isDefined } from "utils/parseUtils"
import { callAccessor, defaultGenericAccessor } from "utils/charts"

import styled from "styled-components"
import { ChartDimensions, DataAccessorSet } from "types/charts"

const AreaChartWrapper = styled.div<{ $flexGrow?: number }>`
  height: 700px;
  width: calc(100% + 1rem);
  margin-bottom: 2rem;
  grid-column: 1 / -1;

  background: white;
  padding: 0.6rem 1rem;
  position: relative;
  ${(props) => (props.$flexGrow ? `flex: ${props.$flexGrow};` : null)}
  flex: 3 1 auto;
`

const TooltipContentWrapper = styled.div`
  max-width: 350px;

  & h4 {
    margin-bottom: 1rem;
    font-size: 16px;
  }
  & span {
    font-weight: bold;
  }
`
const TooltipMessage = styled.p`
  margin-bottom: 1rem;
  font-size: 1rem;
`
const TooltipBar = styled.div`
  height: 20px;
  width: 300px;
  border: solid 1px #a7a7ae;
  margin-left: 25px;
`

const Title = styled.h2`
  margin: 0;
  grid-column: 1 / -1;
`
const Subtitle = styled.p`
  margin: 1rem 0 0 0;
  grid-column: 1 / -1;
`
const StyledLine = styled((props) => <Line {...props} />)<{ $lineFill?: any }>`
  fill: ${(props) => props.$lineFill};
`

interface ITooltipContentComponent {
  tooltipData: Record<string, any>
  xAccessor: (d?: any, i?: any) => any
  yAccessor: (d?: any, i?: any) => any
  formatDate: (d?: any, i?: any) => string
  formatTooltipMetric: (d?: any, i?: any) => string
  tooltipValue?: any
  tooltipMaxValue?: any
}
const DefaultTooltipContentComponent: React.FC<ITooltipContentComponent> = ({
  tooltipData,
  xAccessor,
  yAccessor,
  formatDate,
  formatTooltipMetric,
  tooltipValue,
  tooltipMaxValue,
}) => {
  if (!tooltipData) return null

  try {
    const date = formatDate(xAccessor(tooltipData))
    const metric = formatTooltipMetric(yAccessor(tooltipData))
    if (!date || !metric) return null
  } catch (err) {
    return null
  }

  const calculatedPercent = Number((tooltipValue / tooltipMaxValue) * 100)
  const percentOfTotal = Number.isNaN(calculatedPercent) ? 0 : calculatedPercent.toFixed(1)

  if (Number.isNaN(calculatedPercent))
    return (
      <TooltipContentWrapper>
        <h4>insufficent data for the {formatDate(xAccessor(tooltipData))} school year.</h4>
      </TooltipContentWrapper>
    )

  return (
    <TooltipContentWrapper>
      <h4>{tooltipData.label && tooltipData.label.toLowerCase()}</h4>

      <TooltipMessage>
        <span>{formatTooltipMetric(tooltipValue)}</span> spent in the {formatDate(xAccessor(tooltipData))} school year.
      </TooltipMessage>

      <small>
        <span>{percentOfTotal}%</span> of the {formatTooltipMetric(tooltipMaxValue)} spent in{" "}
        {formatDate(xAccessor(tooltipData))}
      </small>
      <TooltipBar>
        <div
          style={{
            marginLeft: 1,
            marginTop: 1,
            marginRight: 1,
            width: `${percentOfTotal}%`,
            height: 16,
            background: tooltipData.areaHighlightColor,
          }}
        />
      </TooltipBar>
    </TooltipContentWrapper>
  )
}

interface IStackedAreaChart<T> {
  data?: T[]
  dataAccessors?: DataAccessorSet[]
  title?: string
  subtitle?: string
  defaultDimensions?: ChartDimensions
  flexGrow?: number
  defaultYMin?: number
  defaultYMax?: number
  includeCircles?: boolean
  includeTooltip?: boolean
  formatXAxisTick?: (d?: any, i?: any) => any
  formatYAxisTick?: (d?: any, i?: any) => any
  formatTooltipMetric?: (d?: any, i?: any) => any
  formatDate?: (d?: any, i?: any) => any
  TooltipContentComponent?: typeof DefaultTooltipContentComponent
}
export default function StackedAreaChart<T>({
  data,
  dataAccessors,
  title,
  subtitle,
  defaultDimensions,
  flexGrow = 1,
  defaultYMin,
  defaultYMax,
  formatXAxisTick = (v) => v,
  formatYAxisTick = (v) => d3Format(",")(v),
  includeCircles = true,
  includeTooltip = true,
  formatTooltipMetric = (v) => v,
  formatDate = d3TimeFormat("%Y"),
  TooltipContentComponent = DefaultTooltipContentComponent,
}: IStackedAreaChart<T>) {
  const [tooltipShowing, setTooltipShowing] = useState(false)
  const [tooltipShift, setTooltipShift] = useState(null)
  const [tooltipLocation, setTooltipLocation] = useState({ x: 0, y: 0 })
  const [tooltipData, setTooltipData] = useState({})
  const [tooltipValue, setTooltipValue] = useState(null)
  const [tooltipMaxValue, setTooltipMaxValue] = useState(null)
  // const [tooltipRawValue, setTooltipRawValue] = useState(null)
  // const [tooltipMaxRawValue, setTooltipMaxRawValue] = useState(null)

  const [ref, dimensions] = useChartDimensions(defaultDimensions)

  const stackGenerator = d3Stack()
    .keys(dataAccessors.map((d: DataAccessorSet) => d.yAccessor) as any[])
    .value((d: any, accessor) => ((accessor as unknown) as typeof defaultGenericAccessor)(d))
  // .order(d3StackOrderAscending)
  const series = stackGenerator(data as any[])

  const dataset = series.map((s, i) => {
    const {
      label,
      areaColor,
      areaHighlightColor,
      yAccessor,
      rawMetricAccessor,
      maxAccessor,
      dateAccessor,
    } = dataAccessors[i]

    return s.map((d, i) => ({
      x: dateAccessor(d.data, i),
      // x: new Date(String(d.data[""]).split("-")[1]),
      y0: d[0],
      y1: d[1],
      data: d.data,
      metricAccessor: yAccessor,
      label,
      areaColor,
      areaHighlightColor,
      rawMetricAccessor,
      maxAccessor,
    }))
  })

  const keyAccessor = (d, i?: number) => `${d.x}-${d.label}`
  const xAccessor = (d, i) => d.x
  const yAccessor = (d, i) => d.y1
  const y0Accessor = (d, i) => d.y0

  const groupedByDate = d3Group(dataset.flat(), (d: any) => xAccessor(d, 0).getYear())

  const xMin = d3Min(dataset.flat(), xAccessor)
  const xMax = d3Max(dataset.flat(), xAccessor)
  const yMin = isDefined(defaultYMin) ? defaultYMin : 0
  const yMax = isDefined(defaultYMax) ? defaultYMax : d3Max(dataset.flat(), yAccessor)

  const xScale = d3ScaleTime().domain([xMin, xMax]).range([0, dimensions.boundedWidth])
  const yScale = d3ScaleLinear().domain([yMin, yMax]).range([dimensions.boundedHeight, 0]).nice()

  const xAccessorScaled = (d, i) => xScale(xAccessor(d, i))
  const yAccessorScaled = (d, i) => yScale(yAccessor(d, i))
  const y0AccessorScaled = (d, i) => yScale(y0Accessor(d, i))

  const handleOnMouseEnter = (d, i) => {
    const x = callAccessor(xAccessorScaled, d, i) + dimensions.marginLeft + 15
    const y = callAccessor(yAccessorScaled, d, i) + dimensions.marginTop

    const unstackedValue = d.metricAccessor(data[i])
    const maxValue = d3Max(groupedByDate.get(xAccessor(d, 0).getYear()), yAccessor)

    setTooltipData(d)
    setTooltipValue(unstackedValue)
    setTooltipMaxValue(maxValue)

    if (x > dimensions.boundedWidth - dimensions.boundedWidth * 0.1) {
      setTooltipShift("farRight")
    } else if (x < dimensions.boundedWidth * 0.3) {
      setTooltipShift("farLeft")
    } else {
      setTooltipShift(null)
    }

    setTooltipLocation({ x, y })

    setTooltipShowing(true)
    // }
  }

  const handleOnMouseLeave = () => {
    setTooltipLocation({ x: 0, y: 0 })
    setTooltipShowing(false)
  }

  return (
    <>
      {isDefined(title) && <Title>{title}</Title>}
      {isDefined(subtitle) && <Subtitle>{subtitle}</Subtitle>}
      <AreaChartWrapper ref={ref} $flexGrow={flexGrow}>
        <Tooltip
          isShowing={includeTooltip && tooltipShowing}
          x={tooltipLocation.x}
          y={tooltipLocation.y}
          isFarRight={tooltipShift === "farRight"}
          isFarLeft={tooltipShift === "farLeft"}
        >
          <TooltipContentComponent
            tooltipData={tooltipData}
            xAccessor={xAccessor}
            yAccessor={yAccessor}
            formatDate={formatDate}
            formatTooltipMetric={formatTooltipMetric}
            tooltipValue={tooltipValue}
            tooltipMaxValue={tooltipMaxValue}
          />
          {/* {renderTooltipContent({
            tooltipData,
            xAccessor,
            yAccessor,
            formatDate,
            formatTooltipMetric,
            tooltipValue,
            tooltipMaxValue,
            // tooltipRawValue,
            // tooltipMaxRawValue,
          })} */}
        </Tooltip>

        <Chart fullWidth dimensions={dimensions}>
          <Legend
            x={-90}
            y={-130}
            barXAccessor={10}
            barYAccessor={(d, i) => i * 20}
            barHeightAccessor={10}
            barWidthAccessor={30}
            data={dataset.map((d, i) => ({ label: d[0].label, color: d[0].areaColor })).reverse()}
          />

          <Axis dimensions={dimensions} dimension="x" scale={xScale} formatTick={formatXAxisTick} />
          <Axis dimensions={dimensions} dimension="y" scale={yScale} formatTick={formatYAxisTick} />
          {dataset.map((d, i) => (
            <Fragment key={i}>
              <StyledLine
                data={d}
                type="area"
                xAccessor={xAccessorScaled}
                yAccessor={yAccessorScaled}
                y0Accessor={y0AccessorScaled}
                $lineFill={d[0].areaColor}
                // style={{ fill: d[0].areaColor }}
              />
            </Fragment>
          ))}
          {dataset.map(
            (d, i) =>
              includeCircles && (
                <Circles
                  data={d.filter((cd) => isDefined(yAccessor(cd, 0)) && String(yAccessor(cd, 0)) !== "")}
                  key={i}
                  keyAccessor={keyAccessor}
                  xAccessor={xAccessorScaled}
                  yAccessor={yAccessorScaled}
                  circleColor={"white"}
                  circleStroke={d[0].areaHighlightColor}
                  // circleStrokeWeight={3}
                  isHoverable={true}
                  handleOnHover={(cd, idx) => handleOnMouseEnter(cd, idx)}
                  handleOnLeave={() => handleOnMouseLeave()}
                  radius={3}
                />
              )
          )}
        </Chart>
      </AreaChartWrapper>
    </>
  )
}
