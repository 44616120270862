import styled from "styled-components"

const StyledSVG = styled.svg`
  flex: 1;
  width: 100%;
`

export default function BoxCenteredCircle({ height = 100, margin = 10, radius = 100, className = "" }) {
  return (
    <StyledSVG viewBox={`0 0 1000 1000`} height={"100%"} className={className}>
      <rect x={0} y={0} height={"100%"} width={"100%"} fill="var(--color-primary)" />
      <circle cx={"45%"} cy={"45%"} r={radius} fill="var(--color-accent)" />
    </StyledSVG>
  )
}
