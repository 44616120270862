import * as R from "ramda"
import { ChartDimensions } from "types/charts"

type SVGProps = {
  height: number
  width: string | number
  viewBox?: string
}

interface IChart {
  dimensions: ChartDimensions
  children: React.ReactNode
  viewBox?: string
  noGTransform?: boolean
  fullWidth?: boolean
  className?: string
}
const Chart: React.FC<IChart> = ({ dimensions, children, noGTransform, viewBox, fullWidth, className = "" }) => {
  const gTransform = noGTransform ? `translate(0, 0)` : `translate(${dimensions.marginLeft}, ${dimensions.marginTop})`

  const svgProps: SVGProps = {
    height: dimensions.height,
    width: fullWidth ? "100%" : dimensions.width,
  }
  if (!R.isNil(viewBox)) {
    svgProps.viewBox = viewBox
  }

  return (
    <svg {...svgProps} className={className}>
      <g transform={gTransform}>
        <>{children}</>
      </g>
    </svg>
  )
}

export default Chart
