import {
  defaultGenericAccessor,
  // defaultXAccessor,
  // defaultYAccessor,
  // defaultKeyAccessor,
  // defaultColorAccessor,
} from "utils/charts"
import styled from "styled-components"

const StyledCircle = styled.circle<{ $circleColor?: any; $circleStroke?: any; $isHoverable?: boolean }>`
  fill: ${(props) => props.$circleColor};
  stroke: ${(props) => props.$circleStroke};
  transition: all 0.3s ease-out;
  ${(props) => props.$isHoverable && `cursor: pointer;`}
`

interface ICircles<T> {
  data: T[]
  // keyAccessor: typeof defaultKeyAccessor
  // xAccessor: typeof defaultXAccessor
  // yAccessor: typeof defaultYAccessor
  keyAccessor: typeof defaultGenericAccessor
  xAccessor: typeof defaultGenericAccessor
  yAccessor: typeof defaultGenericAccessor
  radius: number | ((d: any) => number)
  circleColor?: any
  circleStroke?: any
  handleOnHover?: (d?: T, i?: any) => void
  handleOnLeave?: () => void
  isHoverable?: boolean
}
export default function Circles<T>({
  data,
  keyAccessor = defaultGenericAccessor,
  xAccessor = defaultGenericAccessor,
  yAccessor = defaultGenericAccessor,
  radius = 5,
  circleColor = "#9980FA",
  circleStroke = "none",
  handleOnHover = () => {},
  handleOnLeave = () => {},
  isHoverable = false,
}: ICircles<T>) {
  return (
    <>
      {data.map((d, i: number) => (
        <StyledCircle
          key={keyAccessor(d, i)}
          cx={xAccessor(d, i)}
          cy={yAccessor(d, i)}
          r={typeof radius === "function" ? radius(d) : radius}
          stroke={circleStroke}
          $circleColor={circleColor}
          onMouseEnter={() => handleOnHover(d, i)}
          onMouseLeave={() => handleOnLeave()}
          $isHoverable={isHoverable}
        />
      ))}
    </>
  )
}
