import { memo, useMemo } from "react"
import * as R from "ramda"
import { min as d3Min, max as d3Max } from "d3-array"
import { scaleLinear as d3ScaleLinear } from "d3-scale"
import { randomInt as d3RandomInt, randomNormal as d3RandomNormal } from "d3-random"
import {
  IconHome,
  IconNewConstruction,
  IconOffice,
  IconPark,
  IconShop,
  // other shit
} from "components"
import { Axis, Chart, Circles, HalfCircleGauge, VisxHorizontalBarChart, VisxVerticalBarChart } from "visualizations"
import { useChartDimensions } from "hooks/charts/useChartDimensions"

import { getPercentColorScale } from "utils/charts"
import styled from "styled-components"
import { ChartDimensions, GenericMetricAccessor } from "types/charts"

const CharterTraditionalBarChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & text.x-label {
    font-size: 0.9rem;
    font-weight: bold;
    dominant-baseline: start;
    text-anchor: start;
    color: white;
  }

  & .icon-office {
    transform: scale(0.4) !important;
  }
`

type CharterTraditionalBarChartData = Record<string, string | number>
interface ICharterTraditionalBarChart {
  allSchoolsValue: number
  allPublicSchoolsValue: number
  traditionalPublicSchoolsValue: number
  charterSchoolsValue: number
  defaultDimensions?: ChartDimensions
  formatXAxisTick?: (d?: any, i?: any, tick?: any) => string
  colorSchema?: any[]
}
function CharterTraditionalBarChart({
  allSchoolsValue,
  allPublicSchoolsValue,
  traditionalPublicSchoolsValue,
  charterSchoolsValue,
  defaultDimensions = { marginLeft: 60, marginRight: 90, height: 450, width: 450 },
  formatXAxisTick,
  colorSchema = ["rgba(252, 125, 154, 0.302)", "rgba(252, 125, 154, 1)"],
}: ICharterTraditionalBarChart) {
  const data: CharterTraditionalBarChartData[] = [
    { y: "All Schools", x: allSchoolsValue },
    { y: "All Public Schools", x: allPublicSchoolsValue },
    { y: "Traditional Public Schools", x: traditionalPublicSchoolsValue },
    { y: "Charter Schools", x: charterSchoolsValue },
  ]

  const xAccessor = (d: CharterTraditionalBarChartData, i: number): number => d.x as number
  const yAccessor = (d: CharterTraditionalBarChartData, i: number): string => d.y as string
  const labelAccessor = (d: CharterTraditionalBarChartData, i: number): string => yAccessor(d, i) as string
  const barChartMetricAccessor = (d: CharterTraditionalBarChartData, i: number) => xAccessor(d, i)

  return (
    <CharterTraditionalBarChartWrapper>
      <VisxHorizontalBarChart
        data={data}
        colorSchema={colorSchema}
        labelAccessor={labelAccessor}
        metricAccessor={barChartMetricAccessor}
        height={defaultDimensions.height}
        width={defaultDimensions.width}
        formatTick={formatXAxisTick}
        getColorScale={(lo, hi, cs) => getPercentColorScale(lo, hi, colorSchema)}
        showIcons={true}
        iconComponent={<IconOffice className="icon-office" />}
      />
    </CharterTraditionalBarChartWrapper>
  )
}

const SchoolLocaleIconSetWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  & .school-locale-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: var(--elevation-1);
    border-radius: 0.25rem;
    border: solid 1px rgb(202, 202, 202);
    padding: 1rem;
    margin: 0 1rem;
    margin-bottom: 1rem;    
    flex: 1;

    & .icon-and-metric {
      display: flex-align-items: center;
    }

    & .school-locale-metric {
      font-size: 1.7rem;
      margin-bottom: 1rem;
      margin-left: 1rem;
    }
    & .school-locale-label {
      font-size: 1.1rem;
      margin-top: 1rem;
      margin-left: 1rem;      
    }
  }
`

interface ISchoolLocaleIconSet {
  cityValue: number
  suburbsValue: number
  townValue: number
  ruralValue: number
  defaultDimensions?: ChartDimensions
  getColorSchema: (i: number) => [string, string]
  formatMetric?: (d?: any, i?: any, tick?: any) => string
  isPercent?: boolean
}
function SchoolLocaleIconSet({
  cityValue,
  suburbsValue,
  townValue,
  ruralValue,
  defaultDimensions = { width: 100, height: 65 },
  getColorSchema,
  formatMetric,
  isPercent,
}: // ...props
ISchoolLocaleIconSet) {
  const data = [
    { x: "Cities", y: cityValue, Icon: IconNewConstruction },
    { x: "Suburbs", y: suburbsValue, Icon: IconHome },
    { x: "Towns", y: townValue, Icon: IconShop },
    { x: "Rural", y: ruralValue, Icon: IconPark },
  ]

  const metricMin = d3Min(data, (d) => d.y)
  const metricMax = d3Max(data, (d) => d.y)

  return (
    <SchoolLocaleIconSetWrapper>
      {data.map(({ x, y, Icon }, i) => (
        <div className="school-locale-item" key={x}>
          <HalfCircleGauge
            title={x}
            min={isPercent ? 0 : metricMin - metricMin * 0.1}
            max={isPercent ? 100 : metricMax + metricMax * 0.1}
            value={y}
            defaultDimensions={defaultDimensions}
            colorSchema={getColorSchema(i)}
          />
          <p className="school-locale-metric">{formatMetric(y)}</p>
          <div className="icon-and-metric">
            <Icon />
            <p className="school-locale-label">{x}</p>
          </div>
        </div>
      ))}
    </SchoolLocaleIconSetWrapper>
  )
}

const SchoolPopulationScatterPlotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & tspan.small-red {
    font-size: 0.6rem;
    fill: red;
  }
`

interface ISchoolPopulationScatterPlot {
  tiny: number
  xs: number
  small: number
  medium: number
  large: number
  xl: number
  scatterplotJiggleMultiplier: number
  defaultDimensions?: ChartDimensions
  formatYAxisTick?: (d: any, i: any, tick: any) => string
}
function SchoolPopulationScatterPlot({
  tiny,
  xs,
  small,
  medium,
  large,
  xl,
  scatterplotJiggleMultiplier,
  defaultDimensions = { marginLeft: 100, marginRight: 90, height: 450, width: 450 },
  formatYAxisTick = (d, i, tick) => `${d}%`,
}: // ...props
ISchoolPopulationScatterPlot) {
  const [ref, dimensions] = useChartDimensions(defaultDimensions)
  const data = [
    { label: "Less Than 100", xRange: [0, 100], y: tiny },
    { label: "100-199", xRange: [100, 199], y: xs },
    { label: "200-499", xRange: [200, 499], y: small },
    { label: "500-749", xRange: [500, 749], y: medium },
    { label: "750-999", xRange: [750, 999], y: large },
    { label: "1,000 or more", xRange: [1000, 2000], y: xl },
  ]

  const initialYMin = d3Min(data, (d) => d.y)
  const initialYMax = d3Max(data, (d) => d.y)

  const randomYInteger = (y: number) => {
    const rand = d3RandomNormal()()
    const rang = initialYMax - initialYMin
    // const jiggle = scatterplotJiggleMultiplier * ((initialYMax - initialYMin) * 0.02 * 4 )
    const jiggle = scatterplotJiggleMultiplier * rand * rang
    return d3RandomInt(y - jiggle, y + jiggle)()
  }
  const randomXInteger = (half: number, i: number, j: number) => {
    const jiggle = 25 * (i + 1) + 25 * (j + 1)
    return Math.max(50, d3RandomInt(half - jiggle, half + jiggle)() + 50)
  }

  const createX = (d: any, i: number, j: number) => {
    const [lo, hi] = d.xRange
    const half = (lo + hi) * 0.5
    return randomXInteger(half, i, j)
  }

  const createY = (d: any) => {
    return randomYInteger(d.y)
  }

  const finalData = useMemo(
    () =>
      data
        .map((d, i) =>
          new Array(5).fill(0).map((zero, j) => ({
            ...d,
            y: createY(d), // d.y + Number(randomNormal()),
            x: createX(d, i, j),
          }))
        )
        .flat(),
    [tiny, xs, small, medium, large, xl]
  )

  // console.log({ finalData })

  const xAccessor = (d, i) => d.x
  const yAccessor = (d, i) => d.y

  const xMin = 0
  const xMax = 2000
  const yMin = d3Min(finalData, (d, i) => yAccessor(d, i) as number)
  const yMax = d3Max(finalData, (d, i) => yAccessor(d, i) as number)

  const xScale = d3ScaleLinear().domain([xMin, xMax]).range([0, dimensions.boundedWidth]).nice()
  const yScale = d3ScaleLinear()
    .domain([yMin - 2, yMax + 5])
    .range([dimensions.boundedHeight, 0])
    .nice()

  const xAccessorScaled = (d, i) => xScale(xAccessor(d, i))
  const yAccessorScaled = (d, i) => yScale(yAccessor(d, i))
  const keyAccessor = (d, i) => i

  const label = (
    <tspan>
      School Size
      <tspan dy="-7" className="small-red">
        *simulated
      </tspan>
    </tspan>
  )

  return (
    <SchoolPopulationScatterPlotWrapper ref={ref}>
      <>
        <Chart dimensions={dimensions}>
          <Axis dimensions={dimensions} dimension="x" scale={xScale} label={label} slideLabelX={-60} />
          <Axis dimensions={dimensions} dimension="y" scale={yScale} formatTick={formatYAxisTick} />
          <line x1={-5} x2={-5} y1={0} y2={dimensions.boundedHeight} stroke="black" strokeWidth={0.5} />
          <line
            x1={-5}
            x2={dimensions.boundedWidth + 20}
            y1={dimensions.boundedHeight}
            y2={dimensions.boundedHeight}
            stroke="black"
            strokeWidth={0.5}
          />
          <Circles
            data={finalData}
            radius={4}
            keyAccessor={keyAccessor}
            xAccessor={xAccessorScaled}
            yAccessor={yAccessorScaled}
            // colorAccessor={(d, i) => colorAccessor(d, i)}
          />
        </Chart>
      </>
    </SchoolPopulationScatterPlotWrapper>
  )
}

const FreeAndReducedLunchVerticalBarChartWrapper = styled.div`
  & text.x-label {
    font-size: 0.8rem;
    dominant-baseline: start;
    text-anchor: start;
    fill: white;
  }
`

interface IFreeAndReducedLunchVerticalBarChart {
  fewValue: number
  someValue: number
  lotsValue: number
  mostValue: number
  defaultDimensions?: ChartDimensions
  formatYAxisTick?: (d: any, i: any, tick: any) => string
}
function FreeAndReducedLunchVerticalBarChart({
  fewValue,
  someValue,
  lotsValue,
  mostValue,
  defaultDimensions = { marginLeft: 100, marginRight: 50, height: 450, width: 450 },
  formatYAxisTick,
}: IFreeAndReducedLunchVerticalBarChart) {
  const data = [
    { x: "0 - 34%", y: fewValue },
    { x: "35-49%", y: someValue },
    { x: "50-75%", y: lotsValue },
    { x: ` > 75%`, y: mostValue },
  ]

  const xAccessor = (d, i) => d.x
  const yAccessor = (d, i) => d.y
  const labelAccessor = (d, i): string => xAccessor(d, i)
  const barChartMetricAccessor = (d, i) => yAccessor(d, i)
  const colorSchema = ["rgba(20, 40, 219, 0.302)", "rgba(20, 40, 219, 0.8)"]

  const getColorScale = (minValue, maxValue, colorSchema) => {
    return (
      d3ScaleLinear()
        .domain([minValue, maxValue])
        // .range(["rgba(252, 125, 154, 0.202)", "rgba(252, 125, 154, 1)"])
        .range([colorSchema[0], colorSchema[1]])
    )
  }

  return (
    <FreeAndReducedLunchVerticalBarChartWrapper>
      <VisxVerticalBarChart
        data={data}
        xLabel="Percent of Students on Free or Reduced Lunch"
        colorSchema={colorSchema}
        labelAccessor={labelAccessor}
        metricAccessor={barChartMetricAccessor}
        height={defaultDimensions.height}
        width={defaultDimensions.width}
        formatTick={formatYAxisTick}
        getColorScale={getColorScale}
      />
    </FreeAndReducedLunchVerticalBarChartWrapper>
  )
}

const CharacteristicBreakdownWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & rect {
    transition: all 0.2s ease;
  }

  & .icon-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
  }

  // & .chart-grid {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  //   // grid-template-columns: repeat(auto-fit, 1fr);
  //   grid-gap: 2rem;
  //   padding: 0 3rem;
  // }

  & .chart-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    padding: 0 0.1rem;
  }
`

const HorizontalBarChart = memo(CharterTraditionalBarChart) as typeof CharterTraditionalBarChart
const IconGaugeChart = memo(SchoolLocaleIconSet) as typeof SchoolLocaleIconSet
const SchoolSizeScatterChart = memo(SchoolPopulationScatterPlot) as typeof SchoolPopulationScatterPlot
const PovertyVerticalBarChart = memo(FreeAndReducedLunchVerticalBarChart) as typeof FreeAndReducedLunchVerticalBarChart

const getColorSchema = (i: number): [string, string] => {
  return (i + 1) % 3 === 1
    ? ["rgba(20, 40, 219, 0.202)", "rgba(20, 40, 219, 1)"]
    : ["rgba(252, 125, 154, 0.202)", "rgba(252, 125, 154, 1)"]
}

interface ICharacteristicBreakdown {
  datasetByCharacteristic?: Record<string, string | number>[]
  formatMetric: (d: any) => string
  metricAccessor: GenericMetricAccessor
  scatterplotJiggleMultiplier?: number
  isPercent?: boolean
}
export default function CharacteristicBreakdown({
  datasetByCharacteristic,
  formatMetric = (d) => `${d}%`,
  scatterplotJiggleMultiplier = 1,
  metricAccessor,
  isPercent,
}: ICharacteristicBreakdown) {
  if (!datasetByCharacteristic) return <span />
  const keyedData = R.indexBy(R.prop("CHARACTERISTIC"), datasetByCharacteristic)

  // deal with weird bug where it uses a weird hyphen character
  const convertedKeyedData: Record<string, number> = R.keys(keyedData).reduce((acc, k) => {
    acc[String(k).replace("–", "-")] = metricAccessor(keyedData[k])
    return acc
  }, {})

  // console.log({ convertedKeyedData })

  const tiny = R.prop("Less than 100", convertedKeyedData)
  const xs = R.prop("100-199", convertedKeyedData)
  const small = R.prop("200-499", convertedKeyedData)
  const medium = R.prop("500-749", convertedKeyedData)
  const large = R.prop("750-999", convertedKeyedData)
  const xl = R.prop("1000 or more", convertedKeyedData)
  const populationData = { tiny, xs, small, medium, large, xl }

  const fewValue = R.prop("0-34", convertedKeyedData)
  const someValue = R.prop("35-49", convertedKeyedData)
  const lotsValue = R.prop("50-74", convertedKeyedData)
  const mostValue = R.prop("75 or more", convertedKeyedData)

  const cityValue = R.prop("City", convertedKeyedData)
  const suburbsValue = R.prop("Suburban", convertedKeyedData)
  const townValue = R.prop("Town", convertedKeyedData)
  const ruralValue = R.prop("Rural", convertedKeyedData)

  const allSchoolsValue = R.prop("All schools", convertedKeyedData)
  const allPublicSchoolsValue = R.prop("All public schools", convertedKeyedData)
  const traditionalPublicSchoolsValue = R.prop("Traditional public", convertedKeyedData)
  const charterSchoolsValue = R.prop("Charter school", convertedKeyedData)

  return (
    <CharacteristicBreakdownWrapper>
      <div className="icon-grid">
        {/* four icons with numbers above them for city, suburbs, town, and rural */}
        <IconGaugeChart
          cityValue={cityValue}
          suburbsValue={suburbsValue}
          townValue={townValue}
          ruralValue={ruralValue}
          getColorSchema={getColorSchema}
          formatMetric={formatMetric}
          isPercent={isPercent}
        />
      </div>
      <div className="chart-grid">
        {/* horizontal bar chart of all public, traditional, and charter */}
        <HorizontalBarChart
          allSchoolsValue={allSchoolsValue}
          allPublicSchoolsValue={allPublicSchoolsValue}
          traditionalPublicSchoolsValue={traditionalPublicSchoolsValue}
          charterSchoolsValue={charterSchoolsValue}
          formatXAxisTick={formatMetric}
        />

        {/* generate random numbers for school population and then scatterplot them */}
        <SchoolSizeScatterChart
          formatYAxisTick={formatMetric}
          scatterplotJiggleMultiplier={scatterplotJiggleMultiplier}
          {...populationData}
        />
        {/* vertical bar chart of free and reduced lunch level for 0-34, 35-49, 50-74, 75+ */}
        <PovertyVerticalBarChart
          fewValue={fewValue}
          someValue={someValue}
          lotsValue={lotsValue}
          mostValue={mostValue}
          formatYAxisTick={formatMetric}
        />
      </div>
    </CharacteristicBreakdownWrapper>
  )
}
