import { Fragment } from "react"
// import { scaleTime as d3ScaleTime, scaleLinear as d3ScaleLinear } from "d3-scale"
import styled from "styled-components"
import { GenericMetricAccessor } from "types/charts"
import {
  callAccessor,
  defaultColorAccessor,
  defaultGenericAccessor,
  defaultHeightAccessor,
  defaultKeyAccessor,
  defaultLabelAccessor,
  defaultWidthAccessor,
  defaultXAccessor,
  defaultYAccessor,
} from "utils/charts"

const StyledLegendGroup = styled.g`
  outline: solid 1px #cacac7;
  outline-offset: 15px;

  & text {
    // dominant-baseline: middle;
    font-size: 16px;

    & tspan {
      font-weight: 700;
      dominant-baseline: middle;
    }
  }
`

interface ILegend<T> {
  x?: number
  y?: number
  data: T[]
  // width,
  // height,
  keyAccessor?: typeof defaultKeyAccessor
  barXAccessor?: typeof defaultXAccessor | number
  barYAccessor?: (d: any, i?: any) => number
  barHeightAccessor?: typeof defaultHeightAccessor | number
  barWidthAccessor?: typeof defaultWidthAccessor | number
  valueAccessor?: GenericMetricAccessor
  colorAccessor?: typeof defaultColorAccessor
  labelAccessor?: typeof defaultLabelAccessor
}
export default function Legend<T>({
  x,
  y,
  data,
  // width,
  // height,
  keyAccessor = defaultKeyAccessor,
  barXAccessor = defaultXAccessor,
  barYAccessor = defaultYAccessor,
  barHeightAccessor = defaultHeightAccessor,
  barWidthAccessor = defaultWidthAccessor,
  valueAccessor = defaultGenericAccessor,
  colorAccessor = defaultColorAccessor,
  labelAccessor = defaultLabelAccessor,
  ...props
}: ILegend<T>) {
  return (
    <StyledLegendGroup
      className="legend-background"
      transform={`translate(${x}, ${y})`}
      {...props}
      // width={dimensions.width}
      // height={dimensions.height}
    >
      {data.map((d, i) => (
        <Fragment key={keyAccessor(d, i)}>
          <rect
            x={callAccessor(barXAccessor, d, i)}
            y={callAccessor(barYAccessor, d, i)}
            // x={barXAccessor(d, i)}
            // y={barYAccessor(d, i)}
            height={callAccessor(barHeightAccessor, d, i)}
            // height={barHeightAccessor(d, i)}
            width={callAccessor(barWidthAccessor, d, i)}
            // width={barWidthAccessor(d, i)}
            fill={colorAccessor(d, i)}
            stroke="black"
            // style={{ cursor: "pointer" }}
            // onMouseEnter={() => props.handleMouseOver(classNameAccessor(d, i))}
            // onMouseLeave={() => props.handleMouseOver(null)}
          />
          <text
            x={callAccessor(barXAccessor, d, i) + callAccessor(barWidthAccessor, d, i) * 1.5}
            // x={barXAccessor(d, i) + barWidthAccessor(d, i) * 1.5}
            y={callAccessor(barYAccessor, d, i) + callAccessor(barHeightAccessor, d, i) * 1}
            // y={barYAccessor(d, i) + barHeightAccessor(d, i) * 1}
            fill={"black"}
          >
            {labelAccessor(d, i)} <tspan>{valueAccessor(d, i)}</tspan>
          </text>
        </Fragment>
      ))}
    </StyledLegendGroup>
  )
}
