import * as R from "ramda"
import {
  scaleLinear as d3ScaleLinear,
  scaleLog as d3ScaleLog,
  scaleTime as d3ScaleTime,
  scaleOrdinal as d3ScaleOrdinal,
  scaleBand as d3ScaleBand,
} from "d3-scale"
import { format as d3Format } from "d3-format"
import { timeFormat as d3TimeFormat } from "d3-time-format"
import { feature } from "topojson-client"
import {
  min as d3Min,
  // max as d3Max
} from "d3-array"

import {
  // Accessor,
  // IndexedAccessor,
  // StateAccessor,
  Point,
  Province,
  MetricAccessor,
  ChartDimensions,
  // GridPoint,
} from "types/charts"
import { isDateObject } from "utils/dates"

import { provinces } from "constants/data-explorer"

export const callAccessor = (accessor: any, d: any, i: any, ...rest: any[]) =>
  typeof accessor === "function" ? accessor(d, i, ...rest) : accessor

export const combineChartDimensions = (dimensions: ChartDimensions) => {
  const parsedDimensions = {
    marginTop: 40,
    marginRight: 30,
    marginBottom: 100,
    marginLeft: 75,
    ...dimensions,
  }

  return {
    ...parsedDimensions,
    boundedHeight: Math.max(parsedDimensions.height - parsedDimensions.marginTop - parsedDimensions.marginBottom, 0),
    boundedWidth: Math.max(parsedDimensions.width - parsedDimensions.marginLeft - parsedDimensions.marginRight, 0),
  }
}

export const getCoordsOnArc = (angle: number, offset: number = 10): [number, number] => [
  Math.cos(angle - Math.PI / 2) * offset,
  Math.sin(angle - Math.PI / 2) * offset,
]

export const getCoordsOnCircle = (angle: number, offset: number = 10): [number, number] => [
  Math.cos(angle - Math.PI) * offset,
  Math.sin(angle - Math.PI) * offset,
]

const arc = (r: number, sign: number[]): string =>
  r ? `a${r * sign[0]},${r * sign[1]} 0 0 1 ${r * sign[2]},${r * sign[3]}` : ""

export function roundedRect(x: number, y: number, width: number, height: number, r: number[]): string {
  r = [
    Math.min(r[0], height, width),
    Math.min(r[1], height, width),
    Math.min(r[2], height, width),
    Math.min(r[3], height, width),
  ]

  return `M${x + r[0]},${y}h${width - r[0] - r[1]}${arc(r[1], [1, 1, 1, 1])}v${height - r[1] - r[2]}${arc(r[2], [
    1,
    1,
    -1,
    1,
  ])}h${-width + r[2] + r[3]}${arc(r[3], [1, 1, -1, -1])}v${-height + r[3] + r[0]}${arc(r[0], [1, 1, 1, -1])}z`
}

// export const sum = (arr: number[] = []) => arr.reduce((a: number, b: number) => +a + +b, [])

export const getDistance = (a: Point, b: Point): number =>
  Math.sqrt(
    // sqrt of (x1 - x2)^2 + // (y1 - y2)^2
    Math.pow(Math.abs(a.x - b.x), 2) + Math.pow(Math.abs(a.y - b.y), 2)
  )

export const getPointFromAngleAndDistance = (angle: number, distance: number): Point => ({
  x: Math.cos((angle * Math.PI) / 180) * distance,
  y: Math.sin((angle * Math.PI) / 180) * distance,
})

export const randomAroundMean = (mean: number, deviation: number): number => mean + boxMullerRandom() * deviation
const boxMullerRandom = (): number =>
  Math.sqrt(-2.0 * Math.log(Math.random())) * Math.cos(2.0 * Math.PI * Math.random())

export const randomInRange = (min: number, max: number): number => Math.random() * (max - min) + min

export const createGrid = ({ count = 10 }) => {
  const points: { position: Point }[] = []

  for (let i = 0; i < count; i++) {
    for (let j = 0; j < count; j++) {
      const x = count <= 1 ? 0.5 : i / (count - 1) // get number between 0 and 1 for x
      const y = count <= 1 ? 0.5 : j / (count - 1) // get number between 0 and 1 for y

      points.push({
        position: { x, y },
      })
    }
  }

  return points
}

export const createSimpleGrid = ({ count = 10 }: { count?: number }): { position: Point }[] =>
  new Array(count)
    .fill(0)
    .map((_, i) =>
      new Array(count).fill(0).map((_, j) => ({
        position: {
          x: count <= 1 ? 0.5 : j / (count - 1),
          y: count <= 1 ? 0.5 : i / (count - 1),
        },
      }))
    )
    .flat()

/**
 * Linear interpolation function.
 * @param a The lower integer value
 * @param b The upper integer value
 * @param t The value between the two
 * @returns {number}
 */
export const lerp = (a: number, b: number, t: number): number => a * (1 - t) + b * t

//t = current time
//b = start value
//c = change in value
//d = duration
export const easeInOutQuad = (t: number, b: number, c: number, d: number): number => {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

/* 
	Animation curves
*/
// Math.easeInOutQuad = function (t, b, c, d) {
// 	t /= d/2;
// 	if (t < 1) return c/2*t*t + b;
// 	t--;
// 	return -c/2 * (t*(t-2) - 1) + b;
// };

export function easeInQuart(t: number, b: number, c: number, d: number) {
  t /= d
  return c * t * t * t * t + b
}

export function easeOutQuart(t: number, b: number, c: number, d: number) {
  t /= d
  t--
  return -c * (t * t * t * t - 1) + b
}

export function easeInOutQuart(t: number, b: number, c: number, d: number) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t * t * t + b
  t -= 2
  return (-c / 2) * (t * t * t * t - 2) + b
}

export function easeOutElastic(t: number, b: number, c: number, d: number) {
  let s = 1.70158
  let p = d * 0.7
  let a = c
  if (t === 0) return b
  if ((t /= d) === 1) return b + c
  if (!p) p = d * 0.3
  if (a < Math.abs(c)) {
    a = c
    s = p / 4
  } else {
    s = (p / (2 * Math.PI)) * Math.asin(c / a)
  }
  return a * Math.pow(2, -10 * t) * Math.sin(((t * d - s) * (2 * Math.PI)) / p) + c + b
}

const scaleTypes = {
  linear: d3ScaleLinear,
  ordinal: d3ScaleOrdinal,
  time: d3ScaleTime,
  log: d3ScaleLog,
  band: d3ScaleBand,
}

export const createScale = (providedConfig) => {
  const defaultConfig = {
    type: "linear",
    dimension: "x",
    domain: [0, 1],
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
  }
  const config = {
    ...defaultConfig,
    ...providedConfig,
  }

  if (!config.range) {
    config.range =
      config.dimension === "x"
        ? [0, config.width - config.margin.right - config.margin.left]
        : [config.height - config.margin.top - config.margin.bottom, 0]
  }

  const scale = (scaleTypes[config.type] || config.type)()
  scale.range(config.range).domain(config.domain)
  if (config.clamp) scale.clamp(config.clamp)

  return scale
}

export const fakeByStateData = provinces
  .filter((d) => R.not(R.includes("Armed", d.label)) || R.not(R.includes(R.includes("Federated", d.label))))
  .reduce((acc, curr) => {
    acc[curr.label] = curr.label.length
    return acc
  }, {})

export const getAbbreviationForState = (stateName: string): string | undefined => {
  const state = R.find((item) => R.equals(R.prop("label", item), stateName), provinces)
  return state?.value
}

// export const getStateNameFromGeo = (geo): string => R.path(["properties", "gn_name"], geo)
// export const getStateNameFromGeo = (geo: Record<string, Record<string, Record<string, string>>> = ): string => R.path(["properties", "gn_name"], geo)
export const getStateNameFromGeo = (geo: any): string => geo?.properties?.gn_name // R.path(["properties", "gn_name"], geo)

export const mapDataToState = (
  geo: any,
  metricAccessor: MetricAccessor<Record<any, any>>,
  data: Record<string, any>
) => {
  const stateName = getStateNameFromGeo(geo)
  return {
    stateName,
    abbrev: getAbbreviationForState(stateName),
    data: metricAccessor(data, stateName),
  }
}

const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g

export function lowerAndRemovePunctuation(str: string | undefined): string {
  return !R.isNil(str)
    ? String(str).replace(punctuationRegex, "").toLowerCase().trim().replace(/\s/g, "").replace("schooldistrict", "")
    : ""
}

export const getDistrictNameFromGeo = (geo): string | undefined => R.path(["properties", "NAME"], geo)

export const mapDataToDistrict = (geo, metricAccessor: MetricAccessor<Record<any, any>>, data) => {
  const districtName = getDistrictNameFromGeo(geo)
  const abbrev = lowerAndRemovePunctuation(districtName)
  const d = metricAccessor(data, abbrev)

  return {
    districtName,
    abbrev,
    data: d,
  }
}

export const getStateColor = (geo, metricAccessor: MetricAccessor<Record<any, any>>, data, colorScale): string => {
  const stateName = getStateNameFromGeo(geo)
  const d = metricAccessor(data, stateName)
  return colorScale(d)
}

export const getDataForYear = (year: number, dataGroupedByYear: Record<string, any>) => {
  if (R.prop(String(year), dataGroupedByYear)) return R.prop(String(year), dataGroupedByYear)

  let yearValue = year - 1

  const minYear = d3Min(R.keys(dataGroupedByYear))

  while (!R.isNil(minYear) && yearValue >= Number(minYear)) {
    if (R.prop(String(yearValue), dataGroupedByYear)) return R.prop(String(yearValue), dataGroupedByYear)
    yearValue -= 1
  }

  return {}
}

export const hasValidMetric = (d: any, i: number, metricAccessor: MetricAccessor<Record<any, any>>) =>
  !R.isNil(metricAccessor(d, i)) && String(metricAccessor(d, i)) !== ""
export const coerceMetricAccessor = (d: any, i: number, metricAccessor: MetricAccessor<Record<any, any>>) =>
  hasValidMetric(d, i, metricAccessor) ? metricAccessor(d, i) : 0
export const roundToNearestTens = (d: number) => Math.round(d / 10) * 10
export const roundDownToNearestTens = (d: number) => Math.floor(d / 10) * 10
export const roundUpToNearestTens = (d: number) => Math.ceil(d / 10) * 10
export const formatWithCommas = d3Format(",")
export const formatYear = d3TimeFormat("%Y")
// export const roundMoneyToThousandsAndAddK = (d) => `$${String(d).slice(0, 2)}.${String(d)[2]}k`
export const roundToThousandsAndAddK = (d: number): string => `${Number(d / 1000).toFixed(1)}K`
export const roundMoneyToThousandsAndAddK = (d: number): string => `$${roundToThousandsAndAddK(d)}`
export const roundToMillionsAndAddM = (d: number): string => `${Number(d / 1000000).toFixed(1)}M`
export const roundMoneyToMillionsAndAddM = (d: number): string => `$${roundToMillionsAndAddM(d)}`
export const roundToBillionsAndAddB = (d: number): string => `${Number(d / 1000000000).toFixed(1)}B`
export const roundMoneyToBillionsAndAddB = (d: number): string => `$${roundToBillionsAndAddB(d)}`

export const roundToThousandsOrMillions = (d: number): string => {
  const newVal = Math.abs(d) / 1000
  if (newVal < 1) return formatWithCommas(d)

  const millionsVal = Math.abs(d) / 1000000
  if (millionsVal < 1) return roundToThousandsAndAddK(d)

  return roundToMillionsAndAddM(d)
}

export const roundToThousandsOrMillionsOrBillions = (d: number): string => {
  const newVal = Math.abs(d) / 1000
  if (newVal < 1) return formatWithCommas(d)

  const millionsVal = Math.abs(d) / 1000000
  if (millionsVal < 1) return roundToThousandsAndAddK(d)

  const billionsVal = Math.abs(d) / 1000000000
  if (billionsVal < 1) return roundToMillionsAndAddM(d)

  return roundToBillionsAndAddB(d)
}

export function getMapFeatures(geographies, parseGeographies) {
  if (Array.isArray(geographies)) return parseGeographies ? parseGeographies(geographies) : geographies

  const feats = R.prop("features", geographies)
    ? geographies.features
    : feature(geographies, geographies.objects[Object.keys(geographies.objects)[0]])

  return parseGeographies ? parseGeographies(feats) : feats
}

export const getPercentColorScale = (
  minValue: number,
  maxValue: number,
  colorSchema: any,
  minSchemaPerc: number = 0,
  maxSchemaPerc: number = 1
) => {
  if (Array.isArray(colorSchema)) {
    return d3ScaleLinear().domain([minValue, maxValue]).range([colorSchema[0], colorSchema[1]])
  }

  return d3ScaleLinear()
    .domain([minValue, maxValue])
    .range([colorSchema(minSchemaPerc), colorSchema(maxSchemaPerc)])
}

export function defaultDateAccessor(d: Record<string, Date>, i?: number): Date
export function defaultDateAccessor(d: Record<string, Date>): Date
export function defaultDateAccessor(d?: any, i?: any): Date {
  return d.date
}

export function defaultNumericalAccessor(d: Record<string, number>, i?: number): number
export function defaultNumericalAccessor(d: Record<string, number>): number
export function defaultNumericalAccessor(d?: any, i?: any): number {
  return d.data
}

export function defaultXAccessor(d: Record<string, number>, i?: number): number
export function defaultXAccessor(d: Record<string, any>, i?: number): any
export function defaultXAccessor(d: Record<string, Date>, i?: number): Date
export function defaultXAccessor(d: Record<string, number>): number
export function defaultXAccessor(d: Record<string, Date>): Date
export function defaultXAccessor(d: Record<string, any>): any
export function defaultXAccessor(d?: any, i?: any): any {
  return d.x
}

export function defaultYAccessor(d: Record<string, number>, i?: number): number
export function defaultYAccessor(d: Record<string, any>, i?: number): any
export function defaultYAccessor(d: Record<string, number>): number
export function defaultYAccessor(d: Record<string, Date>): Date
export function defaultYAccessor(d: Record<string, any>): any
export function defaultYAccessor(d?: any, i?: any): any {
  return d.y
}

export function defaultKeyAccessor(d: Record<string, any>, i?: number): string
export function defaultKeyAccessor(d: Record<string, any>): string
export function defaultKeyAccessor(d?: any, i?: any): any {
  return i
}

export function defaultLabelAccessor(d: Record<string, any>, i?: number): string
export function defaultLabelAccessor(d: Record<string, any>): string
export function defaultLabelAccessor(d: Record<string, any>, i?: number): string {
  return d.label
}

export function defaultWidthAccessor(d: Record<string, number>, i?: number): number
export function defaultWidthAccessor(d: Record<string, any>, i?: number): any
export function defaultWidthAccessor(d: Record<string, number>): number
export function defaultWidthAccessor(d: Record<string, any>): any
export function defaultWidthAccessor(d?: any, i?: any): any {
  return d.width
}

export function defaultHeightAccessor(d: Record<string, number>, i?: number): number
export function defaultHeightAccessor(d: Record<string, any>, i?: number): any
export function defaultHeightAccessor(d: Record<string, number>): number
export function defaultHeightAccessor(d: Record<string, any>): any
export function defaultHeightAccessor(d?: any, i?: any): any {
  return d.height
}

export function defaultColorAccessor(d: Record<string, any>, i?: number): any {
  return d.color || "var(--color-highlight)"
}

export function defaultGenericAccessor<T extends Record<string, any>>(
  d: T,
  i?: string | number
): number | string | boolean | Date | any {
  const v = d.value
  if (typeof v === "number") return v as number
  if (typeof v === "string") return v as string
  if (typeof v === "boolean") return v as boolean
  if (isDateObject(v)) return v as Date

  return v
}

export function makeAccessor<T>(d: T, propName?: string) {
  function accessor(d: T, i?: number): number | string | boolean | Date | any {
    const val = d[propName]
    if (typeof val === "number") return val as number
    if (typeof val === "string") return val as string
    if (typeof val === "boolean") return val as boolean
    if (isDateObject(val)) return val as Date

    return val
  }

  return accessor
}

export function cleanCsvRows(csvData: Record<string, string>[]): Record<string, string>[] {
  return csvData.map((d) =>
    R.keys(d).reduce((acc, k) => {
      acc[k.trim()] = R.replace(/,/g, "", String(d[k]))
      return acc
    }, {})
  )
}
