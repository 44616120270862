import { useState, memo } from "react"
import * as R from "ramda"
import { scaleLinear as d3ScaleLinear } from "d3-scale"
import { Button } from "components"
import { DatasetPicker, HalfCircleGauge, VisxHorizontalBarChart } from "visualizations"
import { formatWithCommas, roundToThousandsOrMillions, roundToThousandsOrMillionsOrBillions } from "utils/charts"
import { motion, AnimatePresence } from "framer-motion"
import { hsl as d3Hsl } from "d3-color"
import styled from "styled-components"

const DistrictsTableWrapper = styled.div`
  & > h4 {
    margin-bottom: 1.5rem;
  }

  .revenue-charts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .percentage-charts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .gauge-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;

    & .gauge-value {
      font-size: 2rem;
    }
  }
  .by-aggregate {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`

const RowTitle = styled.div<{ $rank?: number }>`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => (Number(props.$rank) % 2 === 0 ? `var(--color-accent)` : `var(--color-primary)`)};
  color: white;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem 0;
  cursor: pointer;
`
const AccordianItemWrapper = styled.div`
  & section {
    transform-origin: top center;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow: hidden;

    & > span.content {
      display: block;
      margin-bottom: 20px;
    }
  }
`

const nameAccessor = (d, i) => R.prop("School_District_Name", d)
const stateAccessor = (d, i) => R.prop("State", d)
const nameAndStateAccessor = (d, i) => nameAccessor(d, i) + " - " + stateAccessor(d, i)
const labelAccessor = (d, i) => `${nameAccessor(d, i)}-${i}`
const rankAccessor = (d, i) => R.prop("Rank_Order", d)

const posNameAndStateAccessor = (d, i) => `${R.prop("pos", d)}. ${nameAndStateAccessor(d, i)}`
const enrollmentAccessor = (d, i) => R.prop("Enrollment", d)
const federalRevenuePerStudentAccessor = (d, i) => Number(R.prop("Federal_Revenue_Per_Student", d))
const totalRevenueAccessor = (d, i) => Number(R.prop("Total_Revenue", d))
const revenueFromFreeReducedLunchAccessor = (d, i) => Number(R.prop("Revenue_From_Federal_School_Lunch_Program", d))
const revenueFromDisabilitiesActAccessor = (d, i) =>
  Number(R.prop("Revenue_From_Individuals_With_Disabilities_Education_Act", d))
const revenueFromTitle1Accessor = (d, i) => Number(R.prop("Revenue_From_Title_I", d))
const totalStudentsLivingInPovertyAccessor = (d, i) =>
  Number(R.prop("student_population_in_poverty_5_to_17_years_old", d))
const federalPercentOfTotalAccessor = (d, i) => Number(R.prop("Federal_Revenue_Percent_Of_Total", d))
const percentOfStudentsInPovertyAccessor = (d, i) =>
  Number(R.prop("percent_of_students_in_poverty_5_to_17_years_old", d))

export default function DistrictsTable({ districts, byAggregate, setByAggregate }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <DistrictsTableWrapper>
      <h4>
        Data from 2017 for the 120 largest school districts -{" "}
        <a href="https://nces.ed.gov/programs/digest/d17/tables/dt17_215.30.asp" target="_blank" rel="noreferrer">
          source
        </a>
      </h4>

      <DatasetPicker
        selected={Boolean(byAggregate) ? "By Aggregate" : "By District"}
        setSelected={(selected) => setByAggregate(selected === "By Aggregate")}
        optionsMapping={{
          "By Aggregate": "By Aggregate",
          "By District": "By District",
        }}
      />

      {byAggregate ? (
        <ByAggregate districts={districts} />
      ) : (
        <ByDistrict districts={districts} expanded={expanded} setExpanded={setExpanded} />
      )}
    </DistrictsTableWrapper>
  )
}

function ByDistrict({ districts, expanded, setExpanded }) {
  return (
    <>
      <p>Click on a district to see more.</p>
      {districts.map((d, i) => (
        <AccordianRow
          rank={rankAccessor(d, i)}
          key={rankAccessor(d, i)}
          id={`district-table-row-accordian-${rankAccessor(d, i)}`}
          isExpanded={expanded === `district-table-row-accordian-${rankAccessor(d, i)}`}
          setExpanded={setExpanded}
          district={d}
          allDistricts={districts}
        />
      ))}
    </>
  )
}

const getTopN = (allDistricts, metricAccessor, N = 30) => {
  return R.sort((a, b) => metricAccessor(b) - metricAccessor(a), allDistricts)
    .map((d: any, pos) => ({
      ...d,
      pos: pos + 1,
    }))
    .slice(0, N)
    .reverse()
}

const MemoizedVisxHorizontalBarChart = memo((props) => (
  <VisxHorizontalBarChart {...props} barLabelStyles={{ fontWeight: 300 }} />
)) as typeof VisxHorizontalBarChart

function ByAggregate({ districts }) {
  return (
    <div className="by-aggregate">
      <MemoizedVisxHorizontalBarChart
        title={"Districts' Total Revenue"}
        data={getTopN(districts, totalRevenueAccessor)}
        width={600}
        height={800}
        labelAccessor={posNameAndStateAccessor}
        keyAccessor={labelAccessor}
        metricAccessor={totalRevenueAccessor}
        formatTick={(d) => `$${roundToThousandsOrMillionsOrBillions(d)}`}
        getColorScale={getColorScale}
        // colorSchema={["rgba(252, 125, 154, 0.202)", "rgba(252, 125, 154, 1)"]}
        colorSchema={["#c8e6c9", d3Hsl("hsl(123, 22%, 39%)").brighter(1.75)]}
      />
      <MemoizedVisxHorizontalBarChart
        title={"Districts' Federal Revenue Per Student"}
        data={getTopN(districts, federalRevenuePerStudentAccessor)}
        width={600}
        height={800}
        labelAccessor={posNameAndStateAccessor}
        keyAccessor={labelAccessor}
        metricAccessor={federalRevenuePerStudentAccessor}
        formatTick={(d) => `$${roundToThousandsOrMillions(d)}`}
        getColorScale={getColorScale}
        colorSchema={["rgba(20, 40, 219, 0.08)", "rgba(20, 40, 219, 0.2)"]}
      />
      <MemoizedVisxHorizontalBarChart
        title={"Districts' Revenue From Federal Lunch Program"}
        data={getTopN(districts, revenueFromFreeReducedLunchAccessor)}
        width={600}
        height={800}
        labelAccessor={posNameAndStateAccessor}
        keyAccessor={labelAccessor}
        metricAccessor={revenueFromFreeReducedLunchAccessor}
        formatTick={(d) => `${roundToThousandsOrMillions(d)}`}
        getColorScale={getColorScale}
        colorSchema={[d3Hsl("#7e57c2").brighter(1.2), d3Hsl("#7e57c2").brighter(1)]}
      />
      <MemoizedVisxHorizontalBarChart
        title={"Districts' Revenue From Disabilities Act"}
        data={getTopN(districts, revenueFromDisabilitiesActAccessor)}
        width={600}
        height={800}
        labelAccessor={posNameAndStateAccessor}
        keyAccessor={labelAccessor}
        metricAccessor={revenueFromDisabilitiesActAccessor}
        formatTick={(d) => `${roundToThousandsOrMillions(d)}`}
        getColorScale={getColorScale}
        colorSchema={[d3Hsl("#ffc107").brighter(1.2), d3Hsl("#ffc107").brighter(1)]}
      />
      <MemoizedVisxHorizontalBarChart
        title={"Districts' Revenue From Title 1"}
        data={getTopN(districts, revenueFromTitle1Accessor)}
        width={600}
        height={800}
        labelAccessor={posNameAndStateAccessor}
        keyAccessor={labelAccessor}
        metricAccessor={revenueFromTitle1Accessor}
        formatTick={(d) => `${roundToThousandsOrMillions(d)}`}
        getColorScale={getColorScale}
        colorSchema={[d3Hsl("#8d6e63").brighter(1.8), d3Hsl("#8d6e63").brighter(1.4)]}
      />
      <MemoizedVisxHorizontalBarChart
        title={"Districts' Students Aged 5-17 Living in Poverty"}
        data={getTopN(districts, totalStudentsLivingInPovertyAccessor)}
        width={600}
        height={800}
        labelAccessor={posNameAndStateAccessor}
        keyAccessor={labelAccessor}
        metricAccessor={totalStudentsLivingInPovertyAccessor}
        formatTick={(d) => `${roundToThousandsOrMillions(d)}`}
        getColorScale={getColorScale}
        colorSchema={[d3Hsl("#ef5350").brighter(1.1), d3Hsl("#ef5350").brighter(0.7)]}
      />
      <MemoizedVisxHorizontalBarChart
        title={"Federal Revenue's Contribution to Total Revenue"}
        data={getTopN(districts, federalPercentOfTotalAccessor)}
        width={600}
        height={800}
        labelAccessor={posNameAndStateAccessor}
        keyAccessor={labelAccessor}
        metricAccessor={federalPercentOfTotalAccessor}
        formatTick={(d) => `${Number(d).toFixed(1)}%`}
        getColorScale={getColorScale}
        colorSchema={[d3Hsl("#ec407a").brighter(1.4), d3Hsl("#ec407a").brighter(1)]}
      />
      <MemoizedVisxHorizontalBarChart
        title={"Percent of Student Population In Poverty"}
        data={getTopN(districts, percentOfStudentsInPovertyAccessor)}
        width={600}
        height={800}
        labelAccessor={posNameAndStateAccessor}
        keyAccessor={labelAccessor}
        metricAccessor={percentOfStudentsInPovertyAccessor}
        formatTick={(d) => `${Number(d).toFixed(1)}%`}
        getColorScale={getColorScale}
        colorSchema={[d3Hsl("#26c6da").brighter(1.6), d3Hsl("#26c6da").brighter(1.2)]}
      />
    </div>
  )
}

const accordianVariants = {
  open: {
    // transition: {
    //   type: "spring",
    //   delay: 0,
    //   stiffness: 250,
    //   damping: 48,
    //   mass: 3,
    //   duration: 0.5,
    // },
    height: "100%",
  },
  closed: {
    // transition: {
    //   type: "tween",
    //   duration: 0.3,
    // },
    height: "0px",
  },
}

// interface AccordianItemProps {
//   id: string
//   title: string
//   renderTitle: (title: string, isExpanded: boolean, toggleExpanded: () => void) => React.ReactNode
//   isExpanded: boolean
//   setExpanded: (p: boolean | string) => void
//   elevation?: number
//   contentHeight?: number
//   shouldToggleOpen?: boolean
//   shouldHandleToggle?: boolean
//   children: React.ReactNode
// }
// const AccordianItem: React.FC<AccordianItemProps> = ({
//   id,
//   title,
//   renderTitle,
//   isExpanded = false,
//   setExpanded,
//   elevation,
//   contentHeight = 500,
//   shouldToggleOpen,
//   shouldHandleToggle,
//   children,
//   ...props
// }) => {
//   const toggleExpanded = () => {
//     setExpanded(isExpanded ? false : id)
//   }

//   return (
//     <AccordianItemWrapper
//       key={id}
//       onClick={() => (shouldHandleToggle ? (shouldToggleOpen ? toggleExpanded() : setExpanded(id)) : null)}
//       style={itemWrapperStyles}
//       {...props}
//     >
//       {renderTitle(title, isExpanded, toggleExpanded)}
//       <motion.section
//         layoutId={id}
//         key={id}
//         initial={"closed"}
//         animate={isExpanded ? "open" : "closed"}
//         variants={accordianVariants}
//       >
//         <span className="content">{children}</span>
//       </motion.section>
//     </AccordianItemWrapper>
//   )
// }

function AccordianRow({ id, rank, district, allDistricts, setExpanded, isExpanded, ...props }) {
  const toggleExpanded = () => {
    setExpanded(isExpanded ? false : id)
  }

  const renderTitle = () => (
    <RowTitle $rank={rank} className="row " onClick={() => toggleExpanded()} {...props} role="button" tabIndex={0}>
      <span>
        {rankAccessor(district, 0)}. &nbsp;
        {nameAccessor(district, 0)}
      </span>
      <span>{formatWithCommas(enrollmentAccessor(district, 0))} students</span>
    </RowTitle>
  )

  return (
    <AccordianItemWrapper key={id}>
      {renderTitle()}
      <motion.section
        // layoutId={id}
        // key={id}
        initial={"closed"}
        animate={isExpanded ? "open" : "closed"}
        variants={accordianVariants}
        transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
      >
        <div className="content">
          <DistrictVisualizations district={district} allDistricts={allDistricts} />
        </div>
      </motion.section>
      {/* <>{title}</> */}
      {/* {renderTitle()}
      <AnimatePresence>
        {isExpanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "100%" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="content">
              <DistrictVisualizations district={district} allDistricts={allDistricts} />
            </div>
          </motion.section>
        )}
      </AnimatePresence> */}
    </AccordianItemWrapper>
  )
}

// const indexFinder = (d, metricAccessor, sortedMetrics) => R.findIndex(R.equals(metricAccessor(d)))
const getTenClosest = (metricAccessor, district, allDistricts) => {
  const total = allDistricts.length
  const sortedByMetric = R.sort((a, b) => metricAccessor(b) - metricAccessor(a), allDistricts).map((d: any, pos) => ({
    ...d,
    pos: pos + 1,
  }))
  const idx = sortedByMetric.findIndex((d, i) => nameAccessor(d, i) === nameAccessor(district, i))
  // if (idx === -1) return []
  if (idx <= 5) {
    return [...sortedByMetric.slice(0, idx), ...sortedByMetric.slice(idx, 11)]
  }
  if (idx + 5 > total) {
    return [...sortedByMetric.slice(idx - (11 - (total - idx)), idx), ...sortedByMetric.slice(idx, total)]
  }

  return [...sortedByMetric.slice(idx - 5, idx), ...sortedByMetric.slice(idx, idx + 6)]
}

const getColorScale = (minValue, maxValue, colorSchema) => {
  return (
    d3ScaleLinear()
      .domain([minValue, maxValue])
      // .range(["rgba(252, 125, 154, 0.202)", "rgba(252, 125, 154, 1)"])
      .range([colorSchema[0], colorSchema[1]])
  )
}

function DistrictVisualizations({ district, allDistricts }) {
  const totalRevenue = getTenClosest(totalRevenueAccessor, district, allDistricts).reverse()
  const federalRevenuePerStudentData = getTenClosest(federalRevenuePerStudentAccessor, district, allDistricts).reverse()
  const freeReducedLunchRevenueData = getTenClosest(
    revenueFromFreeReducedLunchAccessor,
    district,
    allDistricts
  ).reverse()
  const disabilitiesActRevenueData = getTenClosest(revenueFromDisabilitiesActAccessor, district, allDistricts).reverse()
  const title1RevenueData = getTenClosest(revenueFromTitle1Accessor, district, allDistricts).reverse()
  const totalPovertyData = getTenClosest(totalStudentsLivingInPovertyAccessor, district, allDistricts).reverse()

  return (
    <>
      <div className="revenue-charts">
        <VisxHorizontalBarChart
          title={"10 Closest Districts: Total Revenue"}
          data={totalRevenue}
          width={600}
          height={400}
          labelAccessor={posNameAndStateAccessor}
          keyAccessor={labelAccessor}
          metricAccessor={totalRevenueAccessor}
          formatTick={(d) => `$${roundToThousandsOrMillionsOrBillions(d)}`}
          getColorScale={getColorScale}
          // colorSchema={["rgba(252, 125, 154, 0.202)", "rgba(252, 125, 154, 1)"]}
          colorSchema={["#c8e6c9", d3Hsl("hsl(123, 22%, 39%)").brighter(1.75)]}
        />
        <VisxHorizontalBarChart
          title={"10 Closest Districts: Federal Revenue Per Student"}
          data={federalRevenuePerStudentData}
          width={600}
          height={400}
          labelAccessor={posNameAndStateAccessor}
          keyAccessor={labelAccessor}
          metricAccessor={federalRevenuePerStudentAccessor}
          formatTick={(d) => `$${roundToThousandsOrMillions(d)}`}
          getColorScale={getColorScale}
          colorSchema={["rgba(20, 40, 219, 0.08)", "rgba(20, 40, 219, 0.2)"]}
        />
        <VisxHorizontalBarChart
          title={"Closest Districts: Revenue From Federal Lunch Program"}
          data={freeReducedLunchRevenueData}
          width={600}
          height={400}
          labelAccessor={posNameAndStateAccessor}
          keyAccessor={labelAccessor}
          metricAccessor={revenueFromFreeReducedLunchAccessor}
          formatTick={(d) => `${roundToThousandsOrMillions(d)}`}
          getColorScale={getColorScale}
          colorSchema={[d3Hsl("#7e57c2").brighter(1.2), d3Hsl("#7e57c2").brighter(1)]}
        />
        <VisxHorizontalBarChart
          title={"10 Closest Districts: Revenue From Disabilities Act"}
          data={disabilitiesActRevenueData}
          width={600}
          height={400}
          labelAccessor={posNameAndStateAccessor}
          keyAccessor={labelAccessor}
          metricAccessor={revenueFromDisabilitiesActAccessor}
          formatTick={(d) => `${roundToThousandsOrMillions(d)}`}
          getColorScale={getColorScale}
          colorSchema={[d3Hsl("#ffc107").brighter(1.2), d3Hsl("#ffc107").brighter(1)]}
        />
        <VisxHorizontalBarChart
          title={"10 Closest Districts: Revenue From Title 1"}
          data={title1RevenueData}
          width={600}
          height={400}
          labelAccessor={posNameAndStateAccessor}
          keyAccessor={labelAccessor}
          metricAccessor={revenueFromTitle1Accessor}
          formatTick={(d) => `${roundToThousandsOrMillions(d)}`}
          getColorScale={getColorScale}
          colorSchema={[d3Hsl("#8d6e63").brighter(1.8), d3Hsl("#8d6e63").brighter(1.4)]}
        />
        <VisxHorizontalBarChart
          title={"10 Closest Districts: Students Aged 5-17 Living in Poverty"}
          data={totalPovertyData}
          width={600}
          height={400}
          labelAccessor={posNameAndStateAccessor}
          keyAccessor={labelAccessor}
          metricAccessor={totalStudentsLivingInPovertyAccessor}
          formatTick={(d) => `${roundToThousandsOrMillions(d)}`}
          getColorScale={getColorScale}
          colorSchema={["rgba(20, 40, 219, 0.08)", "rgba(20, 40, 219, 0.2)"]}
        />
      </div>
      <div className="percentage-charts">
        <div className="gauge-chart">
          <p>Federal Revenue's Contribution to Total Revenue</p>
          <HalfCircleGauge
            title="federal_revenue_percentage"
            value={federalPercentOfTotalAccessor(district, 0)}
            min={0}
            max={100}
            colorSchema={[d3Hsl("#546e7a").brighter(1.5), d3Hsl("#546e7a").brighter(0.5)]}
            // colorSchema={["rgba(20, 40, 219, 0.08)", "rgba(20, 40, 219, 0.2)"]}
          />
          <p className="gauge-value">{Number(federalPercentOfTotalAccessor(district, 0)).toFixed(1)}%</p>
        </div>
        <div className="gauge-chart">
          <p>Percent of Student Population In Poverty</p>
          <HalfCircleGauge
            title="students_in_poverty"
            value={percentOfStudentsInPovertyAccessor(district, 0)}
            min={0}
            max={100}
            colorSchema={[d3Hsl("#546e7a").brighter(1.5), d3Hsl("#546e7a").brighter(0.5)]}
            // colorSchema={["rgba(20, 40, 219, 0.08)", "rgba(20, 40, 219, 0.2)"]}
          />
          <p className="gauge-value">{Number(percentOfStudentsInPovertyAccessor(district, 0)).toFixed(1)}%</p>
        </div>
      </div>
    </>
  )
}
