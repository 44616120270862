import { useEffect, useState, useRef, MutableRefObject } from "react"
import { ResizeObserver } from "@juggle/resize-observer"
import { combineChartDimensions } from "utils/charts"
import { ChartDimensions } from "types/charts"

export const useChartDimensions = (
  passedSettings: ChartDimensions = {}
): [MutableRefObject<HTMLDivElement | null>, ChartDimensions] => {
  const ref = useRef(null)
  const dimensions = combineChartDimensions(passedSettings)

  const [width, changeWidth] = useState(0)
  const [height, changeHeight] = useState(0)

  useEffect(() => {
    let element: HTMLDivElement | null
    let resizeObserver: ResizeObserver | null

    if (dimensions.width && dimensions.height) {
    } else {
      if (ref.current) {
        element = ref.current
      }
      resizeObserver = new ResizeObserver((entries) => {
        if (!Array.isArray(entries)) return
        if (!entries.length) return

        const entry = entries[0]

        if (width !== entry.contentRect.width) changeWidth(entry.contentRect.width)
        if (height !== entry.contentRect.height) changeHeight(entry.contentRect.height)
      })

      if (element) resizeObserver.observe(element)
    }

    return () => {
      if (element && typeof element !== "undefined" && typeof resizeObserver !== "undefined") {
        resizeObserver.unobserve(element)
      }
    }
  }, [])

  const newSettings = combineChartDimensions({
    ...dimensions,
    width: dimensions.width || width,
    height: dimensions.height || height,
  })

  return [ref, newSettings]
}
