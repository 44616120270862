// import React from "react"
import { useRef } from "react"
import Noise from "utils/noise"
import { hsl as d3Hsl } from "d3-color"
import { Chart } from "components/"
import { combineChartDimensions } from "utils/charts"
import styled from "styled-components"

const Wrapper = styled.div<{ $sizeHeight?: number | string; $sizeWidth?: number | string }>`
  ${(props) =>
    typeof props.$sizeHeight === "number"
      ? `
  height: ${props.$sizeHeight}px;
  `
      : `
  height: ${props.$sizeHeight};
  `}
  ${(props) =>
    typeof props.$sizeWidth === "number"
      ? `
  width: ${props.$sizeWidth}px;
  `
      : `
  width: ${props.$sizeWidth};
  `}  

  min-height: 700px;
  height: calc(100vh - var(--navbar-height) - 10px);
  width: 100vw;
  position: absolute;
  zindex: 1;

  margin: 0 0 0 auto;
`

const dmsSettings = {
  marginTop: 35,
  marginRight: 35,
  marginBottom: 35,
  marginLeft: 35,
}

const noise = new Noise(1738)

export default function TriangleDotBackgroundPattern({
  strokeColor = "rgb(102, 102, 202)",
  fillColor = "hsla(234, 83%, 47%, 0.99)",
  highlightColor = "rgba(252, 125, 154, 1)",
  brightness = 1.85,
  rectSize = 30,
  strokeWidth = 1,
  gridLength = 12,
  gridHeight = 20,
  size = { height: "100%", width: "100%" },
  className = "",
  ...props
}) {
  // const [ref, dimensions] = useChartDimensions(dmsSettings)
  const ref = useRef()
  // @ts-ignore
  const dimensions = combineChartDimensions({ ...dmsSettings, ...size })

  const downDistance = rectSize * 0.333

  /**
   *     a
   * ________
   *        | b
   *        |
   *
   * We have the hypotenuse (rectSize) and downDistance
   * We want a - pythagorean theory
   *
   */

  const stroke = d3Hsl(strokeColor)
  const fill = d3Hsl(fillColor).brighter(1.85)
  const highlightFill = d3Hsl(highlightColor).brighter(0.65)
  const keyAccessor = (startX, startY) => `${startX}-${startY}`
  // const getHypotenuse = (a, b) => Math.sqrt(a * a + b * b)

  const drawShape = (startX, startY, isFilled) => {
    // const hypotenuse = getHypotenuse(rectSize, rectSize)
    const isBrite = Math.random() > 0.8
    const perlinNoise = noise.simplex2(startX, startY)
    // console.log(perlinNoise)

    const getFill = () => {
      if (isFilled) {
        return isBrite ? highlightFill : fill
      }

      return "white"
      // return "none"
    }

    if (Math.random() > 0.3) return null

    return perlinNoise > -0.3 ? drawCircle(startX, startY, getFill()) : drawTriangle(startX, startY, getFill())

    // return Math.random() < 0.4 ? drawCircle(startX, startY, getFill()) : drawTriangle(startX, startY, getFill())
  }

  const drawCircle = (startX, startY, fill) => {
    const cx = startX + rectSize / 2
    const cy = startY + rectSize / 2
    const perlinNoise = noise.simplex2(startX, startY)

    return (
      <circle
        key={keyAccessor(startX, startY)}
        cx={cx + perlinNoise * rectSize}
        cy={cy + perlinNoise * rectSize}
        r={rectSize / 6}
        fill={fill}
        stroke={(stroke as unknown) as string}
        strokeWidth={strokeWidth}
      />
    )
  }

  const drawTriangle = (startX, startY, fill) => {
    const perlinNoise = noise.simplex2(startX, startY)
    startX += perlinNoise * rectSize
    startY += perlinNoise * rectSize
    // const flipped = Math.random() > 0.9
    const flipped = Math.random() > 0.59
    const getPath = () =>
      flipped
        ? `
      M ${startX},${startY}
      l ${rectSize},${0}
      l ${0},${rectSize}
      Z    
    `
        : `
      M ${startX},${startY}
      l ${rectSize},${rectSize}
      l ${-rectSize},${0}
      Z    
    `
    return (
      <path
        key={keyAccessor(startX, startY)}
        stroke={(stroke as unknown) as string}
        strokeWidth={strokeWidth}
        fill={fill}
        d={getPath()}
      />
    )
  }

  return (
    <Wrapper ref={ref} {...props} $sizeHeight={size.height} $sizeWidth={size.width} className={className}>
      <Chart
        dimensions={dimensions}
        // @ts-ignore
        transform={`translate(0, ${downDistance})`}
        style={{ minWidth: "100vw", minHeight: "100vh", position: "absolute", zIndex: 1 }}
      >
        {new Array(gridHeight)
          .fill(0)
          .map((d, row) =>
            new Array(gridLength)
              .fill(0)
              .map((d, col) => drawShape(col * rectSize, row * rectSize, Math.random() < 0.6))
          )}
      </Chart>
    </Wrapper>
  )
}
