import { max as d3Max } from "d3-array"
import {
  callAccessor,
  // defaultXAccessor,
  // defaultYAccessor,
  // defaultKeyAccessor,
  // defaultColorAccessor,
  // defaultWidthAccessor,
  // defaultHeightAccessor,
  defaultGenericAccessor,
} from "utils/charts"

interface IBars {
  data: Record<string, any>[]
  radius: number
  // xAccessor: typeof defaultXAccessor
  // yAccessor: typeof defaultYAccessor
  // keyAccessor: typeof defaultKeyAccessor
  // colorAccessor: typeof defaultColorAccessor
  // widthAccessor: typeof defaultWidthAccessor
  // heightAccessor: typeof defaultHeightAccessor
  xAccessor: typeof defaultGenericAccessor
  yAccessor: typeof defaultGenericAccessor
  keyAccessor: typeof defaultGenericAccessor
  colorAccessor: typeof defaultGenericAccessor
  widthAccessor: typeof defaultGenericAccessor
  heightAccessor: typeof defaultGenericAccessor
}
const Bars: React.FC<IBars> = ({
  data,
  radius = 10,
  xAccessor = defaultGenericAccessor,
  yAccessor = defaultGenericAccessor,
  keyAccessor = defaultGenericAccessor,
  colorAccessor = defaultGenericAccessor,
  widthAccessor = defaultGenericAccessor,
  heightAccessor = defaultGenericAccessor,
  ...props
}) => (
  <>
    {data.map((d, i) => (
      <rect
        key={keyAccessor(d, i)}
        x={callAccessor(xAccessor, d, i)}
        y={callAccessor(yAccessor, d, i)}
        rx={radius || 0}
        width={d3Max([callAccessor(widthAccessor, d, i), 0])}
        height={d3Max([callAccessor(heightAccessor, d, i), 0])}
        style={{
          // ...Styles.histogramBar,
          fill: callAccessor(colorAccessor, d, i, data),
        }}
        {...props}
      />
    ))}
  </>
)

export default Bars
