import { motion } from "framer-motion"
import styled from "styled-components"

const StyledSVG = styled.svg``
const AnimatedG = styled(motion.g)``
const StyledPath = styled(motion.path)<{ $fill?: any }>`
  fill-rule: evenodd;
  stroke: #333;
  stroke-width: 2px;
  fill: ${(props) => props.$fill};
  perspective: 500px;
`

interface IGetGearData {
  teeth: number
  radius: number
  x: number
  y: number
}
const getGearData = ({ teeth, radius, x, y }: IGetGearData) => {
  return [
    { id: "sun", teeth, radius, transform: `scale(1)` },
    { id: "planet-1", teeth: teeth * 2, radius: -radius * 2, transform: "translate(0,-" + radius * 3 + ")" },
    {
      id: "planet-2",
      teeth: teeth * 2,
      radius: -radius * 2,
      transform: "translate(" + -radius * 3 * x + "," + -radius * 3 * y + ")",
    },
    {
      id: "planet-3",
      teeth: teeth * 2,
      radius: -radius * 2,
      transform: "translate(" + radius * 3 * x + "," + -radius * 3 * y + ")",
    },
  ]
}

const constructGearPath = (d): string => {
  const { teeth, radius } = d
  const r2 = Math.abs(radius)
  const r0 = r2 - 8
  const r1 = r2 + 8
  const r3 = 20
  const da = Math.PI / teeth
  let a0 = -Math.PI / 2

  const path = ["M", r0 * Math.cos(a0), ",", r0 * Math.sin(a0)]

  let i = -1
  while (++i < teeth) {
    path.push(
      "A",
      r0,
      ",",
      r0,
      " 0 0,1 ",
      r0 * Math.cos((a0 += da)),
      ",",
      r0 * Math.sin(a0), // first row

      "L",
      r2 * Math.cos(a0),
      ",",
      r2 * Math.sin(a0), // second row

      "L",
      r1 * Math.cos((a0 += da / 3)),
      ",",
      r1 * Math.sin(a0), // third row

      "A",
      r1,
      ",",
      r1,
      " 0 0,1 ",
      r1 * Math.cos((a0 += da / 3)),
      ",",
      r1 * Math.sin(a0), // fourth row

      "L",
      r2 * Math.cos((a0 += da / 3)),
      ",",
      r2 * Math.sin(a0), // fifth row

      "L",
      r0 * Math.cos(a0),
      ",",
      r0 * Math.sin(a0) // last row
    )
  }
  path.push("M0,", -r3, "A", r3, ",", r3, " 0 0,0 0,", r3, "A", r3, ",", r3, " 0 0,0 0,", -r3, "Z")

  return path.join("")
}

interface IGear {
  // data: ReturnType<typeof constructGearPath>
  className?: string
  width?: number
  height?: number
  teeth?: number
  radius?: number
  gearColor?: any
  duration?: number
  multiplier?: number
  speed?: number
  baseAngle?: number
  x?: number
  y?: number
}
export default function Gear({
  width = 660,
  height = 500,
  teeth = 16,
  radius = 80,
  duration = 14,
  multiplier = 11.52,
  speed = 10,
  baseAngle = 500,
  x = Math.sin((2 * Math.PI) / 3),
  y = Math.cos((2 * Math.PI) / 3),
  gearColor = "#9ecae1",
  className = "",
}: IGear) {
  const data = getGearData({ teeth, radius, x, y })

  const angle = baseAngle * speed
  const degrees = angle / (-radius * 2)
  const sunDegrees = angle / radius

  return (
    <StyledSVG height={height} width={width} className={className}>
      <g transform={"translate(" + width / 2 + "," + height / 2 + ")scale(.55)"}>
        {data.map((d) => (
          <AnimatedG
            transform={d.transform}
            key={d.id}
            initial={{ "--rotate": "0deg", "--sun-rotate": "0deg" } as any}
            animate={
              { "--rotate": `${degrees * multiplier}deg`, "--sun-rotate": `${sunDegrees * multiplier}deg` } as any
            }
            transition={{ duration, repeat: Infinity }}
          >
            <StyledPath
              d={constructGearPath(d)}
              $fill={gearColor}
              style={{
                transform: d.id === "sun" ? "rotateZ(var(--sun-rotate))" : "rotateZ(var(--rotate))",
              }}
            />
          </AnimatedG>
        ))}
      </g>
    </StyledSVG>
  )
}
