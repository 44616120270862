import { Fragment, useMemo } from "react"
import * as R from "ramda"
import { min as d3Min, max as d3Max } from "d3-array"
import { scaleBand as d3ScaleBand, scaleLinear as d3ScaleLinear } from "d3-scale"
import { Bar } from "@visx/shape"
import { Group } from "@visx/group"
import { ScaleLinear } from "d3"
import { hasValidMetric } from "utils/charts"
// import {
//   // Accessor,
//   // IndexedAccessor,
//   // StateAccessor,
//   // IndexedLabelAccessor,
//   // MetricAccessor,
//   GenericMetricAccessor,
//   StateAccessor,
// } from "models/charts"
import styled from "styled-components"

const StatesInfoBarSVG = styled.svg`
  & rect {
    transition: all 0.2s ease;
  }
`

const margins = { marginLeft: 25, marginRight: 125, marginTop: 100, marginBottom: 40 }
export interface StatesInfoBarChartProps {
  width: number
  height: number
  labelAccessor: (d?: any, i?: any) => any // GenericMetricAccessor | StateAccessor
  // metricAccessor: MetricAccessor
  // labelAccessor: StateAccessor
  // valueAccessor: GenericMetricAccessor
  metricAccessor: (d: any, stateName?: any) => any
  byStateData: Record<string, any>
  colorScale: ScaleLinear<any, any, never>
  formatMetric: (d: any) => string
}
function StatesInfoBarChart({
  width,
  height,
  labelAccessor,
  metricAccessor,
  byStateData,
  colorScale,
  formatMetric = (d: any) => d,
}: StatesInfoBarChartProps) {
  const dimensions = {
    height,
    width,
    ...margins,
    boundedWidth: width - margins.marginLeft - margins.marginRight,
    boundedHeight: height - margins.marginTop - margins.marginBottom,
  }
  const dataValues = R.filter(
    (d) => R.not(R.includes(d.stateName, ["Date", "State", "STATE", "", "United States"])),
    R.values(byStateData)
  )

  const validDataValues = dataValues.filter(
    (d: any, i: number) => hasValidMetric(d, i, metricAccessor as (d?: any, stateName?: any) => any) // GenericMetricAccessor)
  )
  const valuesToRender = validDataValues
    .filter((d, i) => Boolean(labelAccessor(d, (i as unknown) as string)))
    .sort((a: number, b: number) => metricAccessor(a) - metricAccessor(b))
    .reverse()
  const selectedDataValues = valuesToRender.slice(0, 30)

  const stateNames = selectedDataValues.map((d: any, i: number) => labelAccessor(d, String(i)))

  const yScale = useMemo(
    () =>
      d3ScaleBand()
        .domain([...(stateNames as string[]).reverse(), "USA"])
        .rangeRound([dimensions.boundedHeight, dimensions.marginTop])
        .paddingInner(0.4) // padding between bars
        .paddingOuter(0.4) // padding outside bars
        .align(0.5) // value between 0 and 1 - % of bar width to offset first bar from 0
        .round(true),
    [dimensions.boundedHeight, dimensions.marginTop, stateNames]
  )

  const xMin = d3Min(validDataValues, metricAccessor)
  const xMax = d3Max(validDataValues, metricAccessor)
  const xScale = useMemo(
    () =>
      d3ScaleLinear()
        .domain([xMin, xMax])
        .range([dimensions.marginLeft + 30, dimensions.boundedWidth]),
    [xMin, xMax, dimensions.marginLeft, dimensions.boundedWidth]
  )

  // console.log({ xMin, xMax, dataValues, valuesToRender, selectedDataValues })

  return width < 10 ? null : (
    <StatesInfoBarSVG width={width} height={height}>
      <text x={dimensions.marginLeft - 10} y={yScale("USA") + dimensions.marginTop - 20}>
        Top {selectedDataValues.length} US States
      </text>
      <Group top={dimensions.marginTop} left={dimensions.marginLeft}>
        <>
          {[R.prop("United States", byStateData)].map((d) => {
            const barHeight = yScale.bandwidth()
            const barWidth = xScale(metricAccessor(d))
            const barY = yScale("USA")

            if (!barWidth || !barY) return null

            return (
              <Fragment key="USA">
                <Bar
                  x={dimensions.marginLeft + 20}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={colorScale(metricAccessor(d))}
                  rx={4}
                />
                <text x={-10} y={barY + barHeight}>
                  {`USA`}
                </text>
                <text x={dimensions.marginLeft + 20 + barWidth + 10} y={barY + barHeight}>
                  {formatMetric(metricAccessor(d))}
                </text>
              </Fragment>
            )
          })}
        </>
        {valuesToRender.map((d, i) => {
          const barHeight = yScale.bandwidth()
          const barWidth = xScale(metricAccessor(d))
          const barY = yScale(labelAccessor(d, String(i)) as string)

          if (!barWidth || !barY) return null

          return (
            <Fragment key={labelAccessor(d, String(i)) as string}>
              <Bar
                x={dimensions.marginLeft + 20}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={colorScale(metricAccessor(d))}
                rx={4}
              />
              <text x={-10} y={barY + barHeight}>
                {labelAccessor(d, String(i))}
              </text>
              <text x={dimensions.marginLeft + 20 + barWidth + 10} y={barY + barHeight}>
                {formatMetric(metricAccessor(d))}
              </text>
            </Fragment>
          )
        })}
      </Group>
    </StatesInfoBarSVG>
  )
}

export default StatesInfoBarChart
