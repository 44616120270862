import * as R from "ramda"

export const chunkArray = <T>(arr: T[], chunkSize: number): T[][] => {
  const chunks = []

  let i = 0
  while (i <= arr.length) {
    const chunk = R.take(chunkSize, arr.slice(i))
    chunks.push(chunk)

    i += chunkSize
  }

  return chunks
}
