import { line as d3Line, area as d3Area, curveMonotoneX as d3CurveMonotoneX } from "d3-shape"
import { defaultGenericAccessor } from "utils/charts"
import styled from "styled-components"
// import { DataGeneric } from "models/charts"

const StyledPath = styled.path<{ $type?: string; $lineStrokeColor?: any; $lineStrokeWidth?: number }>`
  transition: all 0.3s ease-out;

  ${(props) =>
    props.$type === "line"
      ? `
    fill: transparent;
    stroke: ${props.$lineStrokeColor};
    stroke-width: ${props.$lineStrokeWidth}px;
    stroke-linecap: round;
  `
      : `
    stroke: ${props.$lineStrokeColor};
    stroke-width: ${props.$lineStrokeWidth}px;
    stroke-linecap: round;      
    fill: rgba(152, 152, 250, 0.185);
  `}
`

interface ILine<T> {
  type?: "line" | "area"
  data: T[]
  xAccessor: typeof defaultGenericAccessor
  yAccessor: typeof defaultGenericAccessor
  y0Accessor?: typeof defaultGenericAccessor | number
  lineStrokeColor?: string
  lineStrokeWidth?: number
  interpolation?: typeof d3CurveMonotoneX
}
export default function Line<T>({
  type = "line",
  data,
  xAccessor,
  yAccessor,
  y0Accessor = 0,
  lineStrokeColor = "#9980FA",
  lineStrokeWidth = 0,
  interpolation = d3CurveMonotoneX,
  ...props
}: ILine<T>) {
  let lineGenerator: ReturnType<typeof d3Line>

  if (type === "line") {
    lineGenerator = d3Line().x(xAccessor).y(yAccessor).curve(interpolation)
  } else if (type === "area") {
    lineGenerator = d3Area()
      .x(xAccessor)
      .y(yAccessor)
      .y0(y0Accessor as typeof defaultGenericAccessor)
      .y1(yAccessor)
      .curve(interpolation)
  } else {
    console.warn("Incorrect line chart type: " + type)
    return null
  }

  return (
    <StyledPath
      d={lineGenerator(data)}
      $type={type}
      $lineStrokeColor={lineStrokeColor}
      $lineStrokeWidth={lineStrokeWidth}
      {...props}
    />
  )
}

// const Line: React.FC<ILine> = ({
//   type = "line",
//   data,
//   xAccessor,
//   yAccessor,
//   y0Accessor = 0,
//   lineStrokeColor = "#9980FA",
//   lineStrokeWidth = 0,
//   interpolation = d3CurveMonotoneX,
//   ...props
// }) => {
//   // use either a line chart or an area chart
//   let lineGenerator: ReturnType<typeof d3Line>

//   if (type === "line") {
//     lineGenerator = d3Line().x(xAccessor).y(yAccessor).curve(interpolation)
//   } else if (type === "area") {
//     lineGenerator = d3Area()
//       .x(xAccessor)
//       .y(yAccessor)
//       .y0(y0Accessor as typeof defaultGenericAccessor)
//       .y1(yAccessor)
//       .curve(interpolation)
//   } else {
//     console.warn("Incorrect line chart type: " + type)
//     return null
//   }

//   return (
//     <StyledPath
//       d={lineGenerator(data)}
//       $type={type}
//       $lineStrokeColor={lineStrokeColor}
//       $lineStrokeWidth={lineStrokeWidth}
//       {...props}
//     />
//   )
// }

// export default Line
