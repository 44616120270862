import { Button } from "components"
import styled from "styled-components"

const DatasetColumnPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;

  & > span {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    transform: translateX(-1rem);
    margin-bottom: 1rem;
  }

  & .buttons {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-flow: row;
    grid-gap: 1rem;
  }
`
const StyledButton = styled((props) => <Button {...props} />)<{ $isActive?: boolean }>`
  & span {
    white-space: inherit !important;
  }
`

interface IDatasetColumnPicker {
  selectedColumn: string
  columnsToLabelMapping: Record<string, string>
  setSelectedColumn: (col: string) => void
}
export default function DatasetColumnPicker({
  selectedColumn,
  columnsToLabelMapping,
  setSelectedColumn,
}: IDatasetColumnPicker) {
  return (
    <DatasetColumnPickerWrapper>
      <span>{columnsToLabelMapping[selectedColumn]}</span>
      <div className="buttons">
        {Object.keys(columnsToLabelMapping).map((col: string) => (
          <StyledButton
            buttonType={"primary"}
            $isActive={selectedColumn === col}
            key={col}
            onClick={() => setSelectedColumn(col)}
          >
            {columnsToLabelMapping[col]}
          </StyledButton>
        ))}
      </div>
    </DatasetColumnPickerWrapper>
  )
}
