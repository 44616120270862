interface IGradient {
  id?: string
  colors?: Array<string | Record<string, any>>
}
const Gradient: React.FC<IGradient> = ({ id = "Gradient", colors = [], ...props }) => {
  return (
    <linearGradient id={id} gradientUnits="userSpaceOnUse" spreadMethod="pad" {...props}>
      {colors.map((color, i) => (
        <stop key={i} offset={`${(i * 100) / (colors.length - 1)}%`} stopColor={color as string} />
      ))}
    </linearGradient>
  )
}

export default Gradient
