import styled from "styled-components"

const TooltipWrapper = styled.div<{
  x?: number
  y?: number
  $isFarLeft?: boolean
  $isFarRight?: boolean
  $isMapTooltip?: boolean
  $isShowing?: boolean
}>`
  opacity: 0;
  position: absolute;
  top: -12px;
  left: 0;
  padding: 0.6em 1em;
  background: #fff;
  text-align: center;
  border: 1px solid #ddd;
  z-index: var(--z-index-small);
  transition: all 0.2s ease-out;
  pointer-events: none;
  font-size: 0.8em;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    ${(props) =>
      props.$isFarRight &&
      !props.$isMapTooltip &&
      `
      left: 90%;  
    `}
    ${(props) =>
      props.$isFarLeft &&
      !props.$isMapTooltip &&
      `
      left: 10%;  
    `}    
    width: 12px;
    height: 12px;
    background: white;
    border: 1px solid #ddd;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: translate(-50%, 50%) rotate(45deg);
    transform-origin: center center;
    z-index: var(--z-index-small);
  }
  ${(props) => `
    transform: translate(calc(-50% + ${props.x}px), calc(-100% + ${props.y}px));
  `}
  ${(props) =>
    props.$isFarRight &&
    `
    transform: translate(calc(-90% + ${props.x}px), calc(-100% + ${props.y}px));
  `}
  ${(props) =>
    props.$isFarLeft &&
    `
    transform: translate(calc(-10% + ${props.x}px), calc(-100% + ${props.y}px));
  `}    
  ${(props) => props.$isMapTooltip && `transform: translate(calc(-50% + ${props.x}px), calc(-100% + ${props.y}px)); `}
  ${(props) =>
    props.$isShowing
      ? `
    opacity: 1;
  `
      : `
    opacity: 0;
  `}
`

interface ITooltip {
  children: React.ReactNode
  x?: number
  y?: number
  isShowing?: boolean
  isFarRight?: boolean
  isFarLeft?: boolean
  isMapTooltip?: boolean
}
const Tooltip: React.FC<ITooltip> = ({
  children,
  x = 0,
  y = 0,
  isShowing = false,
  isFarRight = false,
  isFarLeft = false,
  isMapTooltip = false,
  ...props
}) => {
  // console.log({ farRight, farLeft, isMapTooltip })

  return (
    <TooltipWrapper
      x={x}
      y={y}
      $isShowing={isShowing}
      $isFarLeft={isFarLeft}
      $isFarRight={isFarRight}
      $isMapTooltip={isMapTooltip}
      {...props}
    >
      {children}
    </TooltipWrapper>
  )
}

export default Tooltip
