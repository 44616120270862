import { useState } from "react"
import { format as d3Format } from "d3-format"
import { timeFormat as d3TimeFormat } from "d3-time-format"
import { extent as d3Extent, min as d3Min, max as d3Max } from "d3-array"
import { scaleTime as d3ScaleTime, scaleLinear as d3ScaleLinear } from "d3-scale"

import { Axis, Chart, Circles, Gradient, Line, Tooltip } from "visualizations"
import { useChartDimensions } from "hooks/charts/useChartDimensions"
import { useUniqueId } from "hooks/common/useUniqueId"
import {
  callAccessor,
  // defaultXAccessor,
  // defaultYAccessor,
  // defaultKeyAccessor,
  // defaultDateAccessor,
  // defaultNumericalAccessor,
  defaultGenericAccessor,
} from "utils/charts"
import { isDefined } from "utils/parseUtils"

import styled from "styled-components"
// import { DataGeneric, GenericMetricAccessor } from "models/charts"

const LineChartWrapper = styled.div<{ $flexGrow?: number }>`
  min-height: 300px;
  // width: 100%;
  // min-width: 500px;
  // width: calc(100% + 1em);
  margin-bottom: 2em;

  background: white;
  padding: 0.6em 1em;
  margin: 0.5em;
  position: relative;
  overflow-x: scroll;
  ${(props) => (props.$flexGrow ? `flex: ${props.$flexGrow};` : null)}

  & .line-chart-title {
    margin: 0;
    white-space: nowrap;
    font-size: 1.8rem;
  }
`

const TooltipData = styled.p`
  font-size: 1rem;

  & > span {
    font-weight: bold;
  }
`

interface ILineChart<T> {
  data?: T[]
  label?: string
  title?: string
  flexGrow?: number
  height?: number
  // xAccessor: typeof defaultXAccessor | typeof defaultDateAccessor
  // yAccessor: typeof defaultYAccessor | typeof defaultNumericalAccessor
  // keyAccessor: typeof defaultKeyAccessor
  // xAccessor?: GenericMetricAccessor
  // yAccessor?: GenericMetricAccessor
  // keyAccessor?: GenericMetricAccessor
  keyAccessor?: (d?: any, i?: any) => string
  xAccessor?: (d?: any, i?: any) => any
  yAccessor?: (d?: any, i?: any) => any
  slideLabelX?: number
  slideLabelY?: number
  defaultYMin?: number
  defaultYMax?: number
  circleColor?: any
  circleStroke?: any
  circleStrokeWeight?: number
  gradientColors?: any[]
  lineStrokeColor?: any
  lineStrokeWidth?: number
  formatYAxisTick?: (d: any, i?: any) => any
  formatTooltipMetric?: (d: any, i?: any) => string
  includeArea?: boolean
  includeCircles?: boolean
  includeTooltip?: boolean
  gradientIdName?: string
  formatDate?: (d: any, i?: any) => string
  majorGridlinesAt?: number
}
export default function LineChart<T>({
  data,
  label,
  title,
  flexGrow = 1,
  height = 300,
  xAccessor = defaultGenericAccessor,
  yAccessor = defaultGenericAccessor,
  slideLabelX = 0,
  slideLabelY = 0,
  keyAccessor = defaultGenericAccessor,
  defaultYMin,
  defaultYMax,
  circleColor,
  circleStroke,
  // circleStrokeWeight,
  gradientColors = ["rgb(226, 222, 243)", "#F8F9FA"],
  lineStrokeColor = "#9980FA",
  lineStrokeWidth = 3,
  formatYAxisTick = d3Format(","),
  formatTooltipMetric = (v) => (v as unknown) as string,
  includeArea = true,
  includeCircles = false,
  includeTooltip = false,
  gradientIdName = "gradient-id-for-timeline",
  // majorGridlinesAt = 1,
  formatDate = d3TimeFormat("%Y"),
}: ILineChart<T>) {
  const [ref, dimensions] = useChartDimensions({
    height: height,
    marginLeft: 95,
    marginRight: 60,
    marginBottom: isDefined(title) ? 80 : 40,
  })
  const gradientId = useUniqueId(`Timeline-gradient-${gradientIdName}`)

  const xScale = d3ScaleTime()
    .domain(d3Extent(data, (d: T, i: number) => xAccessor(d, i) as Date))
    .range([0, dimensions.boundedWidth])

  const yMin = isDefined(defaultYMin) ? defaultYMin : d3Min(data, (d: T, i: number) => yAccessor(d, i) as number)
  const yMax = isDefined(defaultYMax) ? defaultYMax : d3Max(data, (d: T, i: number) => yAccessor(d, i) as number)
  // const numGridlines = majorGridlinesAt ? yMax / majorGridlinesAt : 0

  // console.log(new Array(numGridlines).fill(0).map((z, i) => i * majorGridlinesAt))

  const yScale = d3ScaleLinear().domain([yMin, yMax]).range([dimensions.boundedHeight, 0]).nice()

  const xAccessorScaled = (d: T, i: number) => xScale(xAccessor(d) as Date)
  const yAccessorScaled = (d: T, i: number) => yScale(yAccessor(d) as number)
  const y0AccessorScaled = yScale(yScale.domain()[0])

  const [tooltipShowing, setTooltipShowing] = useState(false)
  const [tooltipLocation, setTooltipLocation] = useState({ x: 0, y: 0 })
  const [tooltipData, setTooltipData] = useState({})

  const renderToolTip = () => {
    if (!tooltipData) return null
    try {
      const date = formatDate(xAccessor(tooltipData))
      const metric = formatTooltipMetric(yAccessor(tooltipData))
      if (!date || !metric) return null
    } catch (err) {
      return null
    }

    return (
      <div>
        <>
          <TooltipData>
            {formatDate(xAccessor(tooltipData))}: <span>{formatTooltipMetric(yAccessor(tooltipData))}</span>
          </TooltipData>
        </>
      </div>
    )
  }

  const handleOnHover = (d: T, i: number) => {
    const data = callAccessor(yAccessorScaled, d, i)
    const x = callAccessor(xAccessorScaled, d, i) + dimensions.marginLeft + 15
    const y = callAccessor(yAccessorScaled, d, i) + dimensions.marginTop

    if (data > 0 && isDefined(data) && String(yAccessor(d)) !== "") {
      setTooltipData(d)
      setTooltipLocation({ x, y })
      setTooltipShowing(true)
    }
  }

  const handleOnLeave = () => {
    setTooltipLocation({ x: 0, y: 0 })
    setTooltipShowing(false)
  }

  return (
    <LineChartWrapper ref={ref} $flexGrow={flexGrow} className="Line_Chart">
      <Tooltip isShowing={includeTooltip && tooltipShowing} x={tooltipLocation.x} y={tooltipLocation.y}>
        {renderToolTip()}
      </Tooltip>

      {isDefined(title) && <h2 className="line-chart-title">{title}</h2>}

      <Chart dimensions={dimensions}>
        <defs>
          <Gradient id={gradientId} colors={gradientColors} />
        </defs>
        <line x1={0} x2={0} y1={0} y2={dimensions.boundedHeight} stroke="black" strokeWidth={0.5} />
        <line
          x1={0}
          x2={dimensions.boundedWidth}
          y1={dimensions.boundedHeight}
          y2={dimensions.boundedHeight}
          stroke="black"
          strokeWidth={0.5}
        />
        <Axis dimensions={dimensions} dimension="x" scale={xScale} formatTick={formatDate} />
        <Axis
          dimensions={dimensions}
          dimension="y"
          scale={yScale}
          label={label}
          formatTick={formatYAxisTick}
          slideLabelX={slideLabelX}
          slideLabelY={slideLabelY}
        />

        {/* {majorGridlinesAt && typeof majorGridlinesAt === "number"
          ? new Array(numGridlines)
              .fill(0)
              .map((zero, i) => (
                <line
                  x1={0}
                  x2={dimensions.boundedWidth}
                  y1={yScale(i * majorGridlinesAt)}
                  y2={yScale(i * majorGridlinesAt)}
                  stroke="rgba(206, 206, 206, 0.8)"
                  strokeWidth={1}
                  key={i}
                />
              ))
          : null} */}

        {includeArea && (
          <Line<T>
            type="area"
            data={data}
            // @ts-ignore
            xAccessor={xAccessorScaled}
            // @ts-ignore
            yAccessor={yAccessorScaled}
            y0Accessor={y0AccessorScaled}
            style={{ fill: `url(#${gradientId})` }}
          />
        )}
        <Line<T>
          data={data}
          // @ts-ignore
          xAccessor={xAccessorScaled}
          // @ts-ignore
          yAccessor={yAccessorScaled}
          lineStrokeColor={lineStrokeColor}
          lineStrokeWidth={lineStrokeWidth}
        />
        {includeCircles && (
          <Circles<T>
            data={data.filter((d) => isDefined(yAccessor(d)) && String(yAccessor(d)) !== "")}
            keyAccessor={keyAccessor}
            // @ts-ignore
            xAccessor={(d, i) => xAccessorScaled(d, i)}
            // @ts-ignore
            yAccessor={(d, i) => yAccessorScaled(d, i)}
            circleColor={circleColor}
            circleStroke={circleStroke}
            // circleStrokeWeight={circleStrokeWeight as number}
            isHoverable={includeTooltip}
            handleOnHover={handleOnHover}
            handleOnLeave={handleOnLeave}
            radius={6}
          />
        )}
      </Chart>
    </LineChartWrapper>
  )
}
