import { format as d3Format } from "d3-format"
import { useChartDimensions } from "hooks/charts/useChartDimensions"
import { ChartDimensions } from "types/charts"

interface IAxis {
  dimensions: ChartDimensions
  dimension: string
  slide?: number
  scale?: any
  label?: string | React.ReactNode
  formatTick?: any
  tickStyle?: Record<string, any>
  slideLabelX?: number
  slideLabelY?: number
}
const Axis: React.FC<IAxis> = ({
  dimensions,
  dimension = "x",
  slide = 0,
  scale,
  label,
  formatTick = d3Format(","),
  tickStyle = {},
  slideLabelX = 0,
  slideLabelY = 0,
  ...props
}) => {
  // const [ref, dimensions] = useChartDimensions()
  // const [ref, dimensions] = useChartDimensions()

  if (dimension === "x")
    return (
      <AxisHorizontal
        dimensions={dimensions}
        slide={slide}
        scale={scale}
        label={label}
        {...props}
        tickStyle={tickStyle}
        formatTick={formatTick}
        slideLabelX={slideLabelX}
        slideLabelY={slideLabelY}
      />
    )
  if (dimension === "y")
    return (
      <AxisVertical
        dimensions={dimensions}
        slide={slide}
        scale={scale}
        label={label}
        {...props}
        tickStyle={tickStyle}
        formatTick={formatTick}
        slideLabelX={slideLabelX}
        slideLabelY={slideLabelY}
      />
    )

  return null
}

interface IAxisTick {
  formatTick?: any
  tick: any
  scale?: any
  orientation?: string
  slide: number
  tickStyle: Record<string, any>
}
export const AxisTick: React.FC<IAxisTick> = ({
  formatTick = d3Format(","),
  tick,
  scale,
  orientation,
  slide,
  tickStyle,
  ...props
}) => {
  const text = formatTick(tick)

  return (
    <text
      transform={
        slide
          ? orientation === "horizontal"
            ? `translate(${scale(tick + slide)}, 25)`
            : `translate(-16, ${scale(tick + slide)})`
          : orientation === "horizontal"
          ? `translate(${scale(tick)}, 25)`
          : `translate(-16, ${scale(tick)})`
      }
      style={{
        ...tickStyle,
        textAnchor: orientation === "horizontal" ? "start" : "end",
      }}
      {...props}
    >
      {typeof text === "function" ? text(tick) : text}
    </text>
  )
}

interface IAxisHorizontal {
  dimensions: ChartDimensions
  label?: string | React.ReactNode
  formatTick?: any
  numTicks?: number
  scale?: any
  orientation?: string
  slide: number
  tickStyle?: Record<string, any>
  axisTicks?: any[]
  showLine?: boolean
  slideLabelX?: number
  slideLabelY?: number
}
export const AxisHorizontal: React.FC<IAxisHorizontal> = ({
  dimensions,
  label,
  formatTick,
  scale,
  numTicks,
  axisTicks,
  slide,
  tickStyle,
  showLine = false,
  slideLabelX,
  slideLabelY,
  ...props
}) => {
  const numberOfTicks = numTicks
    ? numTicks
    : dimensions.boundedWidth < 600
    ? dimensions.boundedWidth / 100
    : dimensions.boundedWidth / 250
  // const ticks = scale.ticks(numberOfTicks)
  const ticks = typeof scale.ticks === "function" ? scale.ticks(numberOfTicks) : axisTicks

  return (
    <g transform={`translate(0, ${dimensions.boundedHeight})`} {...props}>
      {showLine && <line x1={0} x2={dimensions.boundedWidth} y1={1} y2={1} style={{ strokeWidth: 1 }} />}
      {ticks.map((tick) => (
        <AxisTick
          key={tick}
          tick={tick}
          scale={scale}
          formatTick={formatTick}
          orientation="horizontal"
          slide={slide}
          tickStyle={tickStyle}
        />
      ))}
      {label && (
        <text style={{ transform: `translate(${dimensions.boundedWidth / 2 + slideLabelX}px, ${56 + slideLabelY}px)` }}>
          {label}
        </text>
      )}
    </g>
  )
}

interface IAxisVertical {
  dimensions: ChartDimensions
  label?: string | React.ReactNode
  formatTick?: any
  numTicks?: number
  scale?: any
  orientation?: string
  slide: number
  tickStyle?: Record<string, any>
  axisTicks?: any[]
  showLine?: boolean
  slideLabelX?: number
  slideLabelY?: number
}
export const AxisVertical: React.FC<IAxisVertical> = ({
  dimensions,
  label,
  formatTick,
  scale,
  numTicks,
  slide,
  tickStyle,
  slideLabelX,
  slideLabelY,
  ...props
}) => {
  const numberOfTicks = numTicks ? numTicks : dimensions.boundedHeight / 70
  const ticks = scale.ticks(numberOfTicks)

  return (
    <g {...props}>
      <line y2={dimensions.boundedHeight} />
      {ticks.map((tick) => (
        <AxisTick
          key={tick}
          tick={tick}
          scale={scale}
          formatTick={formatTick}
          orientation="vertical"
          slide={slide}
          tickStyle={tickStyle}
        />
      ))}
      {label && (
        <text
          style={{
            transform: `translate(${-56 + slideLabelX}px, ${
              dimensions.boundedHeight / 2 + slideLabelY
            }px) rotate(-90deg)`,
          }}
        >
          {label}
        </text>
      )}
    </g>
  )
}

export default Axis
