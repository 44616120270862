import * as R from "ramda"
import { format as d3Format } from "d3-format"
import styled from "styled-components"

const TooltipContentWrapper = styled.div`
  transition: all 0.2s ease-in-out;
`

interface IDefaultContentComponent {
  tooltipData: Record<string, any>
  resource?: string
  [prop: string]: unknown
}
const DefaultContentComponent: React.FC<IDefaultContentComponent> = ({ tooltipData, resource = "Teacher" }) =>
  !R.isNil(R.prop("data", tooltipData)) ? (
    <>
      <strong>
        Average {resource} Salary in {R.prop("year", tooltipData)}
      </strong>
      <p>
        {R.prop("stateName", tooltipData)}: {`$${d3Format(",")(R.prop("data", tooltipData))}`}
      </p>
    </>
  ) : (
    <>
      <strong>Data Unavailable</strong>
    </>
  )

interface IUSAStatesTooltipContent {
  [prop: string]: unknown
  tooltipData?: Record<string, any>
  resource?: string
  TooltipContentComponent?: React.FC<IDefaultContentComponent>
}
const USAStatesTooltipContent: React.FC<IUSAStatesTooltipContent> = ({
  tooltipData,
  resource = "Teacher",
  TooltipContentComponent = DefaultContentComponent,
  ...props
}) => {
  if (!tooltipData?.x || !tooltipData?.y) return <span />

  return (
    <TooltipContentWrapper
    // x={tooltipData.x} y={tooltipData.y} isShowing={tooltipData.isShowing}
    >
      <>
        {tooltipData ? (
          <TooltipContentComponent tooltipData={tooltipData} resource={resource} {...props} />
        ) : (
          <>
            <strong>Data Unavailable</strong>
          </>
        )}
      </>
    </TooltipContentWrapper>
  )
}

export default USAStatesTooltipContent
