import { useMemo } from "react"
import { motion } from "framer-motion"
import { hsl as d3Hsl, hsl } from "d3-color"
import styled from "styled-components"
import Gears from "../Gear/Gear"

const StyledSVG = styled.svg`
  transform: translateX(-15px);
`
const StyledGear = styled((props) => <Gears {...props} />)``

const lineVariants = {
  initial: {
    strokeDashoffset: 0,
  },
  animate: {
    strokeDashoffset: 1240,
  },
}

const drawTrapezoid = (startX, startY, topLength, botLength, height) => {
  const offset = (topLength - botLength) * 0.5
  const path = `
    M ${startX},${startY}
    l ${topLength},${0}
    l ${-offset},${-height}
    l ${-botLength},${0}
    l ${-offset},${height}
  `

  return (
    <svg
      style={{
        // transformOrigin: "center",
        transform: `rotate(25deg)`,
      }}
    >
      <path
        d={path}
        strokeWidth={1}
        fill="var(--color-primary)"
        strokeLinecap="round"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
      />
    </svg>
  )
}

const constructLeftVizCalcs = () => {
  const circleCenter = 200
  const circleRadius = 100
  const rectHeight = 10
  const rectY = circleCenter - rectHeight * 0.5
  const rectWidth = circleCenter
  const smallRectSize = 50
  const smallRectX = rectWidth - smallRectSize * 0.5

  return {
    circleCenter,
    circleRadius,
    rectHeight,
    rectY,
    rectWidth,
    smallRectSize,
    smallRectX,
  }
}

const constructRightVizCalcs = (rightCalcs: ReturnType<typeof constructLeftVizCalcs>) => {
  const {
    circleCenter: leftCircleCenter,
    circleRadius: leftCircleRadius,
    smallRectSize: leftSmallRectSize,
  } = rightCalcs

  const vizGap = 130

  const halfCircleRadius = 70
  const halfCircleCenter = leftCircleCenter + leftCircleRadius + halfCircleRadius + vizGap

  const botCircleOuterRadius = leftSmallRectSize * 0.5 + 10
  const botCircleRadius = botCircleOuterRadius - 10
  const botCircleCenter = halfCircleCenter * 1

  return {
    halfCircleRadius,
    halfCircleCenter,
    botCircleOuterRadius,
    botCircleRadius,
    botCircleCenter,
  }
}

const LeftViz = ({ height, calcs, lineStroke }) => {
  const { circleCenter, circleRadius, rectHeight, rectY, rectWidth, smallRectSize, smallRectX } = calcs

  return (
    <>
      <circle
        cx={circleCenter}
        cy={circleCenter}
        r={circleRadius + 10}
        fill={"var(--pure-white)"}
        stroke={lineStroke}
        strokeWidth={1}
      />
      <circle
        cx={circleCenter}
        cy={circleCenter}
        r={circleRadius + 10}
        fill={"var(--color-primary-a20)"}
        stroke={lineStroke}
        strokeWidth={1}
      />
      <circle cx={circleCenter} cy={circleCenter} r={circleRadius} fill={"var(--color-secondary)"} />
      <StyledGear
        height={400}
        width={400}
        radius={40}
        teeth={8}
        gearColor={"var(--background-color-very-light-blue)"}
      />
      {/* <rect x={-75} y={rectY} height={rectHeight} width={rectWidth + 75} fill={"var(--pure-white)"} /> */}
      <motion.rect
        x={-75}
        y={rectY}
        height={rectHeight}
        width={rectWidth + 75}
        fill={"var(--font-color-subdued)"}
        rx={4}
        // stroke={"white"}
        strokeWidth={1}
        stroke={lineStroke}
        strokeDasharray={4}
        variants={lineVariants}
        animate="animate"
        initial="initial"
        transition={{ duration: 14, repeat: Infinity }}
      />

      <line x1={rectWidth} x2={rectWidth} y1={0} y2={height} strokeWidth={1.5} stroke={lineStroke} />
      <rect
        x={smallRectX}
        y={height - smallRectSize}
        width={smallRectSize}
        height={smallRectSize}
        fill={"var(--color-accent)"}
        stroke={lineStroke}
        // stroke={lineStroke}
      />
    </>
  )
}

const RightViz = ({ height, calcs, background, lineStroke }) => {
  const { halfCircleRadius, halfCircleCenter, botCircleOuterRadius, botCircleRadius, botCircleCenter } = calcs

  const halfCircleY = 0
  const botCircleY = height - botCircleOuterRadius * 0.85

  const lineY2 = height - botCircleOuterRadius * 0.8

  return (
    <>
      <circle
        cy={halfCircleY}
        cx={halfCircleCenter}
        r={halfCircleRadius}
        fill={"var(--color-accent)"}
        stroke={lineStroke}
      />
      <rect
        x={halfCircleCenter}
        y={0}
        width={halfCircleRadius + 2}
        height={halfCircleRadius + 2}
        fill={"var(--pure-white)"}
      />
      <rect x={halfCircleCenter} y={0} width={halfCircleRadius + 2} height={halfCircleRadius + 2} fill={background} />

      <circle cx={botCircleCenter} cy={botCircleY} r={botCircleOuterRadius} fill={"var(--pure-white)"} />
      <circle
        cx={botCircleCenter}
        cy={botCircleY}
        r={botCircleOuterRadius}
        fill={"var(--color-primary-a10)"}
        stroke={lineStroke}
      />
      <circle cx={botCircleCenter} cy={botCircleY} r={botCircleRadius} fill={"var(--color-secondary)"} />
      <line
        x1={botCircleCenter}
        x2={botCircleCenter}
        y1={0}
        y2={lineY2}
        // stroke={"var(--background-color-very-light-blue)"}
        stroke={lineStroke}
      />
      <line
        x1={botCircleCenter - botCircleOuterRadius * 2}
        x2={botCircleCenter + botCircleOuterRadius * 2}
        y1={height - botCircleOuterRadius * 1.6}
        y2={height}
        stroke={lineStroke}
      />
    </>
  )
}

export type PolygonProps = {
  width: number
  height: number
  background?: any
  lineStroke?: any
}

export default function ConstructionShapes({
  width,
  height,
  lineStroke = "var(--font-color-less-dark)",
  background = hsl("#605dec").brighter(0.8),
  // background = "rgb(230, 234, 242)",
  // background = "var(--color-primary-a30)",
  // background = "var(--background-color-lightest-blue)",
  // background = "var(--background-color-very-light-blue)",
  ...props
}: PolygonProps) {
  const leftCalcs = useMemo(() => constructLeftVizCalcs(), [])
  const rightCalcs = useMemo(() => constructRightVizCalcs(leftCalcs), [])

  return (
    <StyledSVG width={width} height={height}>
      <rect width={width} height={height} fill={background} rx={14} />

      {drawTrapezoid(40, height, 60, 30, 60)}

      <line x1={70} x2={70} y1={0} y2={height * 0.6} stroke={lineStroke} strokeWidth={1} />
      <circle cx={70} cy={height * 0.6} r={15} fill={"var(--color-accent)"} stroke={lineStroke} />

      <g transform={`translate(100, 0)`}>
        <LeftViz height={height} calcs={leftCalcs} lineStroke={lineStroke} />

        <RightViz height={height} calcs={rightCalcs} background={background} lineStroke={lineStroke} />
      </g>
    </StyledSVG>
  )
}
