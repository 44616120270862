import { Fragment, useMemo } from "react"
import { coerceNumber } from "@visx/scale"

import { scaleBand as d3ScaleBand, scaleLinear as d3ScaleLinear } from "d3-scale"

import { AxisBottom, AxisLeft } from "@visx/axis"
import { Text } from "@visx/text"
import { Bar } from "@visx/shape"
import { Group } from "@visx/group"

import styled from "styled-components"
import { ChartDimensions, GenericMetricAccessor } from "types/charts"

const HorizontalBarChartSVG = styled.svg`
  & .bottom-label {
    transform: translateX(-50%);
  }
`

const getMinMax = (vals) => {
  const numericVals = vals.map(coerceNumber)
  return [Math.min(...numericVals), Math.max(...numericVals)]
}

interface IVerticalBarChart<T> {
  data?: T[]
  width?: number
  height?: number
  title?: string
  keyAccessor?: (d?: any, i?: any) => string
  labelAccessor?: (d?: any, i?: any) => string
  metricAccessor?: (d?: any, i?: any) => any
  getColorScale?: (xMin?: number, xMax?: number, colorSchema?: any) => (d?: any, i?: any) => any
  colorSchema?: any
  margins?: ChartDimensions
  offsetMultiplier?: number
  paddingInner?: number
  paddingOuter?: number
  align?: number
  round?: boolean
  formatTick?: (d?: any, i?: any, tick?: any) => string
  xLabel?: string
}
export default function VerticalBarChart<T>({
  data,
  width,
  height,
  labelAccessor,
  metricAccessor,
  getColorScale,
  colorSchema,
  margins = { marginLeft: 40, marginRight: 50, marginTop: 50, marginBottom: 40 },
  offsetMultiplier = 0.1, // how much to multiply xMin by to increase space on left
  paddingInner = 0.4,
  paddingOuter = 0.4,
  align = 0.5,
  round = true,
  formatTick = (d) => `${d}`,
  xLabel,
  ...props
}: IVerticalBarChart<T>) {
  // console.log(byStateData)
  const dimensions = {
    height,
    width,
    ...margins,
    boundedWidth: width - margins.marginLeft - margins.marginRight,
    boundedHeight: height - margins.marginTop - margins.marginBottom,
  }

  // const xDomain = R.map((d, i) => labelAccessor(d, i), data)
  const xDomain = data.map((d, i) => labelAccessor(d, i) as string)
  const xScale = useMemo(
    () =>
      d3ScaleBand()
        .domain(xDomain)
        .rangeRound([dimensions.marginLeft, dimensions.boundedWidth])
        .paddingInner(paddingInner) // padding between bars
        .paddingOuter(paddingOuter) // padding outside bars
        .align(align) // value between 0 and 1 - % of bar width to offset first bar from 0
        .round(round),
    [dimensions.boundedWidth, dimensions.marginLeft, xDomain, paddingInner, paddingOuter, align, round]
  )

  const linearValues = data.map((d) => metricAccessor(d))
  const [yMin, yMax] = getMinMax(linearValues)
  const yScale = useMemo(() => {
    return (
      d3ScaleLinear()
        .domain([yMin - yMin * offsetMultiplier, yMax])
        //
        .range([dimensions.marginTop, dimensions.boundedHeight])
    )
  }, [dimensions.marginTop, dimensions.boundedHeight, yMin, yMax, offsetMultiplier])

  const tickScale = d3ScaleLinear()
    .domain([yMax, yMin - yMin * offsetMultiplier])
    .range([dimensions.marginTop, dimensions.boundedHeight])

  const colorScale = getColorScale(yMin, yMax, colorSchema)

  const scaledBarWidthAccessor = (d, i) => xScale.bandwidth()
  const scaledBarHeightAccessor = (d, i) => yScale(metricAccessor(d, i) as number)
  const scaledBarXAccessor = (d, i) => xScale(labelAccessor(d, i) as string)
  const scaledBarYAccessor = (d, i) => dimensions.boundedHeight - 0 - scaledBarHeightAccessor(d, i)
  const colorAccessor = (d, i) => colorScale(metricAccessor(d, i))

  const tickFormat = (d, idx, tick) => formatTick(d, idx, tick)
  //
  return width < 10 ? null : (
    <HorizontalBarChartSVG width={width} height={height} {...props}>
      {/* <GradientTealBlue id="teal" /> */}
      {/* <rect width={width} height={height} fill="url(#teal)" rx={14} /> */}
      {/* <AxisLeft /> */}
      <LeftAxis
        dimensions={dimensions}
        yScale={tickScale}
        tickFormat={tickFormat}
        // values={yScale.ticks()}
      />
      <BottomAxis
        dimensions={dimensions}
        xScale={xScale}
        values={xDomain}
        label={xLabel}
        // tickFormat={tickFormat}
      />
      <Group left={dimensions.marginLeft}>
        {data.map((d, i) => {
          if (!scaledBarWidthAccessor(d, i) || !scaledBarXAccessor(d, i)) return null

          return (
            <Fragment key={labelAccessor(d) as string}>
              <Bar
                x={scaledBarXAccessor(d, i)}
                y={scaledBarYAccessor(d, i)}
                width={scaledBarWidthAccessor(d, i)}
                height={scaledBarHeightAccessor(d, i)}
                fill={colorAccessor(d, i)}
                rx={4}
              />
            </Fragment>
          )
        })}
      </Group>
    </HorizontalBarChartSVG>
  )
}

const StyledTick = styled(Text)`
  font-size: 16px;
`

const Tick = ({ formattedValue, ...props }) => (
  <Group top={5}>
    <StyledTick {...props} angle={0}>
      {formattedValue}
    </StyledTick>
  </Group>
)

interface IBottomAxis {
  dimensions?: ChartDimensions
  xScale?: any
  values?: string[] | number[]
  tickFormat?: (d?: any, i?: any, tick?: any) => string
  label?: string
}
const BottomAxis: React.FC<IBottomAxis> = ({ dimensions, xScale, values, tickFormat, label }) => {
  return (
    <Group left={dimensions.marginLeft}>
      <AxisBottom
        top={dimensions.boundedHeight}
        scale={xScale}
        label={label}
        tickFormat={tickFormat}
        tickValues={values}
        stroke={"black"}
        tickStroke={"black"}
        tickLabelProps={() => ({
          fill: "black",
          fontSize: 12,
          textAnchor: "middle",
          // transform: `rotate(45, 45, 45)`,
        })}
        labelClassName="bottom-label"
        labelOffset={30}
        labelProps={{
          fontSize: 18,
        }}
        tickComponent={Tick}
      />
    </Group>
  )
}

const StyledLeftTick = styled(Text)`
  font-size: 16px;
`

const LeftTick = ({ formattedValue, ...props }) => (
  <Group top={0} left={-30}>
    <StyledLeftTick {...props} angle={0}>
      {formattedValue}
    </StyledLeftTick>
  </Group>
)

interface ILeftAxis {
  dimensions?: ChartDimensions
  yScale?: any
  values?: string[] | number[]
  tickFormat?: (d?: any, i?: any, tick?: any) => string
}
const LeftAxis: React.FC<ILeftAxis> = ({ dimensions, yScale, values, tickFormat }) => {
  return (
    <Group left={dimensions.marginLeft + 40}>
      <AxisLeft
        top={0}
        hideTicks
        tickValues={values}
        scale={yScale}
        tickFormat={tickFormat}
        stroke={"black"}
        tickStroke={"black"}
        tickLabelProps={() => ({
          fill: "black",
          fontSize: 12,
          textAnchor: "middle",
          // transform: `rotate(45, 45, 45)`,
        })}
        tickComponent={LeftTick}
      />
    </Group>
  )
}
