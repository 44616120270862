import { memo, forwardRef, Fragment } from "react"
import * as R from "ramda"
import { AlbersUsa, Graticule } from "@visx/geo"
import { LegendLinear, LegendItem, LegendLabel } from "@visx/legend"
import { StatesInfoBarChart } from "visualizations"
import { ScaleLinear } from "d3"
import { GeoPermissibleObjects } from "@visx/geo/lib/types"
// import { defaultLabelAccessor } from "utils/charts"
// import {
//   // MetricAccessor,
//   // Accessor,
//   // IndexedLabelAccessor,
//   // StateAccessor,
//   GenericMetricAccessor,
//   StateAccessor,
// } from "types/charts"

const background = "#F9F7E8"
const MemoizedStatesBarChart = memo(StatesInfoBarChart)
const barChartWidth: number = 310

// const abbrevAccessor: GenericMetricAccessor = (d: Record<string, any>, i: number): string => d.abbrev
// const abbrevAccessor = (d: Record<string, number | string>, i: number | string): string => d.abbrev as string

export interface MapLegendProps {
  title: string
  otherTitle: string
  colorScale: ScaleLinear<any, any, never>
  formatLegendMetric: (d: any, i: number) => string
}
function MapLegend({ title, otherTitle, colorScale, formatLegendMetric }: MapLegendProps) {
  return (
    <div className="legend-wrapper">
      <h3>{title}</h3>
      {otherTitle && <h4>{otherTitle}</h4>}
      <div className="legend-bar">
        <LegendLinear scale={colorScale} labelFormat={(d, i) => formatLegendMetric(d, i)}>
          {(labels) =>
            labels.map((label, i) => (
              <LegendItem className="legend-item" flexDirection="column" key={`legend-linear-${i}`}>
                <svg width={120} height={20}>
                  <rect x={0} y={0} height={20} width={120} fill={label.value} />
                </svg>
                <LegendLabel align="left">{label.text}</LegendLabel>
              </LegendItem>
            ))
          }
        </LegendLinear>
      </div>
    </div>
  )
}

export interface USAMapProps {
  data: Record<string, any>
  title: string
  features: GeoPermissibleObjects[]
  otherTitle: string
  width?: number
  height?: number
  defaultCenterX?: number
  defaultCenterY?: number
  handleOnMouseEnter?: (event: any, feature: any) => void
  handleOnMouseLeave?: () => void
  getStateColor?: (feature: any) => string
  colorScale: ScaleLinear<any, any, never>
  showLegend?: boolean
  showBarChart?: boolean
  labelAccessor?: (d?: any, i?: any) => any // GenericMetricAccessor | StateAccessor
  // valueAccessor?: MetricAccessor
  stateBarChartMetricAccessor?: (d?: any, stateName?: any) => any // GenericMetricAccessor | StateAccessor
  stateBarChartLabelAccessor?: (d?: any, i?: any) => any
  formatLegendMetric?: (d: any, i: number) => string
  formatBarChartMetric?: (d: any) => string
  stateBorderColor?: string
}
const USAMap = forwardRef<SVGSVGElement, USAMapProps>(
  (
    {
      data,
      title,
      features,
      otherTitle,
      width = 800,
      height = 600,
      defaultCenterX,
      defaultCenterY,
      handleOnMouseEnter,
      handleOnMouseLeave,
      getStateColor,
      colorScale,
      showLegend,
      showBarChart,
      labelAccessor,
      stateBarChartMetricAccessor,
      stateBarChartLabelAccessor,
      formatLegendMetric,
      formatBarChartMetric,
      stateBorderColor = background,
    },
    ref
  ) => {
    const centerX = defaultCenterX || width / 2
    const centerY = defaultCenterY || height / 2
    const scale = width * 0.9

    return (
      <>
        <svg width={width} height={height} ref={ref}>
          <rect x={0} y={-0} width={width} height={height} fill={background} rx={14} />
          <AlbersUsa data={features} scale={scale} translate={[centerX + barChartWidth * 0.5, centerY + 50]}>
            {(projection) => (
              <g>
                <Graticule graticule={(g) => projection.path(g) || ""} stroke="rgba(33,33,33,0.05)" />
                {projection.features.map(({ feature, path }, i) => (
                  <Fragment key={`map-feature-${i}`}>
                    <path
                      className={`map-state-path map-state-${R.path(["properties", "abbrev"], feature)}`}
                      d={path || ""}
                      style={{ fill: getStateColor(feature) || "#ababb7" }}
                      stroke={stateBorderColor}
                      strokeWidth={0.5}
                      onMouseEnter={(event) => handleOnMouseEnter(event, feature)}
                      onMouseLeave={() => handleOnMouseLeave()}
                    />
                  </Fragment>
                ))}
              </g>
            )}
          </AlbersUsa>

          {showBarChart && (
            <MemoizedStatesBarChart
              byStateData={data}
              width={barChartWidth}
              height={height - 50}
              colorScale={colorScale}
              labelAccessor={stateBarChartLabelAccessor || labelAccessor}
              metricAccessor={stateBarChartMetricAccessor}
              formatMetric={formatBarChartMetric}
            />
          )}
        </svg>
        {showLegend && (
          <MapLegend
            colorScale={colorScale}
            title={title}
            otherTitle={otherTitle}
            formatLegendMetric={formatLegendMetric}
          />
        )}
      </>
    )
  }
)

export default USAMap
