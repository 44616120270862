import { Button, Card } from "components"
import { motion, AnimateSharedLayout } from "framer-motion"
import styled from "styled-components"
import media from "utils/mediaQueryTemplates"

const TabSelectorWrapper = styled.div<{
  $isFullWidth?: boolean
  $isCentered?: boolean
  $size?: string
  $breakPoint?: string
}>`
  display: flex;
  flex-direction: row;
  ${(props) => (props.$isFullWidth ? `width: 100%;` : ``)}
  max-width: var(--mobile-width);

  ${(props) =>
    props.$isCentered &&
    `
    justify-content: center;
  `}

  & ul {
    flex: 1;
    display: flex;

    ${(props) =>
      props.$isFullWidth
        ? `
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    `
        : `
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    `}

    & li {
      margin: 1rem;
      position: relative;

      & button {
        font-size: ${(props) => {
          switch (props.$size) {
            case "xs":
              return "0.8rem"
            case "small":
              return "0.9rem"
            case "medium":
              return "1rem"
            default:
              return "1.2rem"
          }
        }};
        text-align: left;
      }
    }
  }

  .underline {
    width: 110%;
    height: 5px;
    border-radius: 4px;
    position: absolute;
    bottom: -1px;
    left: -5%;
    background: var(--color-primary-a50);
  }

  ${(props) => media[props.$breakPoint]`
    & ul {
      flex-direction: row;
      align-items: center;

      & li {
        margin: 0 1rem;

        & button {
          text-align: center;
        }
      }
    }
  `}
`

const StyledCard = styled(Card)<{ $breakPoint?: string; $smallPadding?: boolean }>`
  padding: ${(props) => (props.$smallPadding ? "1rem" : "1rem 2.5rem")};
  border-radius: 1.5rem;
  margin-bottom: 1rem;

  ${(props) => media[props.$breakPoint]`
    padding: 1rem 2.5rem;
  `}
`

interface ITabsSelector<Item> {
  items: Item[]
  value?: string
  onSelect: (v: string) => void
  elevation?: number
  isFullWidth?: boolean
  isCentered?: boolean
  size?: string
  breakPoint?: string
  smallPadding?: boolean
}
export default function TabsSelector<Item extends { value: string; label: string }>({
  items,
  value,
  onSelect,
  elevation = 0,
  isFullWidth = false,
  isCentered = false,
  size = "medium",
  breakPoint = "tablet",
  smallPadding = false,
}: ITabsSelector<Item>) {
  const handleSelect = (tabValue) => {
    onSelect(tabValue)
  }

  return (
    <TabSelectorWrapper
      // style={props.style || {}}
      $isFullWidth={isFullWidth}
      $isCentered={isCentered}
      $size={size}
      $breakPoint={breakPoint}
    >
      <StyledCard $smallPadding={smallPadding} $breakPoint={breakPoint}>
        <AnimateSharedLayout>
          <ul>
            {items.map((tab) => (
              <motion.li key={tab.value} className={value === tab.value ? "selected" : ""}>
                <Button buttonType="plain" onClick={() => handleSelect(tab.value)}>
                  {tab.label}
                </Button>
                {value === tab.value && <motion.div className="underline" layoutId="underline" />}
              </motion.li>
            ))}
          </ul>
        </AnimateSharedLayout>
      </StyledCard>
    </TabSelectorWrapper>
  )
}
