import { Fragment, useMemo } from "react"
import { coerceNumber } from "@visx/scale"

import { scaleBand as d3ScaleBand, scaleLinear as d3ScaleLinear } from "d3-scale"

import { AxisBottom } from "@visx/axis"
import { Text } from "@visx/text"
import { Bar } from "@visx/shape"
import { Group } from "@visx/group"

import styled from "styled-components"
import { ChartDimensions, DataGeneric, GenericMetricAccessor } from "types/charts"

const HorizontalBarChartSVG = styled.svg``

const BarLabel = styled(Text)`
  font-weight: bold;
`

const getMinMax = (vals) => {
  const numericVals = vals.map(coerceNumber)
  return [Math.min(...numericVals), Math.max(...numericVals)]
}

interface IHorizontalBarChart<T> {
  data?: T[]
  width?: number
  height?: number
  title?: string
  // keyAccessor?: GenericMetricAccessor
  // labelAccessor?: GenericMetricAccessor
  // metricAccessor?: GenericMetricAccessor
  keyAccessor?: (d?: any, i?: any) => string
  labelAccessor?: (d?: any, i?: any) => string
  metricAccessor?: (d?: any, i?: any) => any
  getColorScale?: (xMin?: number, xMax?: number, colorSchema?: any) => (d?: any, i?: any) => any
  colorSchema?: any
  margins?: ChartDimensions
  offsetMultiplier?: number
  paddingInner?: number
  paddingOuter?: number
  align?: number
  round?: boolean
  formatTick?: (d?: any, i?: any, tick?: any) => string
  showIcons?: boolean
  iconComponent?: React.ReactNode
  barLabelStyles?: Record<string, any>
}
export default function HorizontalBarChart<T = DataGeneric>({
  data,
  width,
  height,
  title,
  keyAccessor,
  labelAccessor,
  metricAccessor,
  getColorScale,
  colorSchema,
  margins = { marginLeft: 25, marginRight: 50, marginTop: 50, marginBottom: 40 },
  offsetMultiplier = 0.1, // how much to multiply xMin by to increase space on left
  paddingInner = 0.4,
  paddingOuter = 0.4,
  align = 0.5,
  round = true,
  formatTick = (d?: any, i?: any, tick?: any) => `${d}`,
  showIcons = false,
  iconComponent,
  barLabelStyles = {},
  ...props
}: IHorizontalBarChart<T>) {
  // console.log(byStateData)
  const dimensions = {
    height,
    width,
    ...margins,
    boundedWidth: width - margins.marginLeft - margins.marginRight,
    boundedHeight: height - margins.marginTop - margins.marginBottom,
  }

  const domain = data.map((d, i) => labelAccessor(d, i) as string)
  const yScale = useMemo(
    () =>
      d3ScaleBand()
        .domain(domain)
        .rangeRound([dimensions.boundedHeight, dimensions.marginTop])
        .paddingInner(paddingInner) // padding between bars
        .paddingOuter(paddingOuter) // padding outside bars
        .align(align) // value between 0 and 1 - % of bar width to offset first bar from 0
        .round(round),
    [dimensions.boundedHeight, dimensions.marginTop, domain, paddingInner, paddingOuter, align, round]
  )

  const linearValues = data.map((d) => metricAccessor(d))
  const [xMin, xMax] = getMinMax(linearValues)
  const xScale = useMemo(() => {
    return (
      d3ScaleLinear()
        .domain([xMin - xMin * offsetMultiplier, xMax])
        //
        .range([dimensions.marginLeft, dimensions.boundedWidth])
    )
  }, [dimensions.marginLeft, dimensions.boundedWidth, xMin, xMax, offsetMultiplier])

  const colorScale = getColorScale(xMin, xMax, colorSchema)

  const scaledBarHeightAccessor = (d, i) => yScale.bandwidth()
  const scaledBarWidthAccessor = (d, i) => xScale(metricAccessor(d, i) as number)
  const scaledBarYAccessor = (d, i) => yScale(labelAccessor(d, i) as string)
  const scaledBarXAccessor = (d, i) => dimensions.marginLeft
  const colorAccessor = (d, i) => colorScale(metricAccessor(d, i))

  const scaledBarLabelXAccessor = (d, i) => scaledBarXAccessor(d, i) + 10
  const scaledBarLabelYAccessor = (d, i) => scaledBarYAccessor(d, i) + scaledBarHeightAccessor(d, i) * 0.75

  const tickFormat = (d: any, idx: number, tick: any) => formatTick(d, idx, tick)

  const iconYAccessorScaled = (d, i) => scaledBarLabelYAccessor(d, i) - scaledBarHeightAccessor(d, i) * 0.85

  return width < 10 ? null : (
    <HorizontalBarChartSVG width={width} height={height} {...props}>
      <BottomAxis dimensions={dimensions} xScale={xScale} values={linearValues} tickFormat={tickFormat} />
      <Group left={dimensions.marginLeft}>
        {data.map((d, i) => {
          if (!scaledBarWidthAccessor(d, i) || !scaledBarYAccessor(d, i)) return null

          return (
            <Fragment key={keyAccessor ? (keyAccessor(d, i) as string) : (labelAccessor(d, i) as string)}>
              <Bar
                x={scaledBarXAccessor(d, i)}
                y={scaledBarYAccessor(d, i)}
                width={scaledBarWidthAccessor(d, i)}
                height={scaledBarHeightAccessor(d, i)}
                fill={colorAccessor(d, i)}
                rx={4}
              />
              <BarLabel x={scaledBarLabelXAccessor(d, i)} y={scaledBarLabelYAccessor(d, i)} style={barLabelStyles}>
                {labelAccessor(d, i) as string}
              </BarLabel>
            </Fragment>
          )
        })}
        <>
          {title ? (
            <Text x={0} y={35} fontSize="1.15rem">
              {title}
            </Text>
          ) : null}
        </>
      </Group>
      {showIcons
        ? data.map((d, i) => (
            <Group key={i} top={iconYAccessorScaled(d, i)} left={-1}>
              {iconComponent}
            </Group>
          ))
        : null}
    </HorizontalBarChartSVG>
  )
}

const StyledTick = styled(Text)``

const Tick = ({ formattedValue, ...props }) => (
  <Group top={15}>
    <StyledTick {...props} angle={45}>
      {formattedValue}
    </StyledTick>
  </Group>
)

const BottomAxis = ({ dimensions, xScale, values, tickFormat }) => {
  return (
    <Group left={dimensions.marginLeft}>
      <AxisBottom
        top={dimensions.boundedHeight}
        scale={xScale}
        tickFormat={tickFormat}
        stroke={"black"}
        tickStroke={"black"}
        tickLabelProps={() => ({
          fill: "black",
          fontSize: 12,
          textAnchor: "middle",
        })}
        tickComponent={Tick}
      />
    </Group>
  )
}
