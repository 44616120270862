import { Chart } from "components"
import { useChartDimensions } from "hooks/charts/useChartDimensions"
import styled from "styled-components"

const StyledContainer = styled.div`
  width: 100%;
  max-width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
`

const SemiCircleGroup = styled.g``

const dmsSettings = {
  marginTop: 20,
  marginRight: 20,
  marginBottom: 20,
  marginLeft: 0,
}

interface ISoundWaveBackground {
  numCircles?: number
}
export default function SoundWaveBackground({ numCircles = 20, ...props }: ISoundWaveBackground) {
  const [ref, dimensions] = useChartDimensions(dmsSettings)

  return (
    <StyledContainer ref={ref} {...props}>
      <Chart dimensions={dimensions}>
        <SemiCircleGroup>
          {new Array(numCircles).fill(0).map((_, i) => (
            <circle
              key={i}
              cx={-2}
              cy={dimensions.boundedHeight * 0.5}
              r={10 * ((i + 1) * (0.75 * i))}
              fill="none"
              stroke={[4, 13].includes(i) ? "var(--color-accent)" : "var(--color-primary-a50)"}
              strokeWidth={2}
            />
          ))}
        </SemiCircleGroup>
      </Chart>
    </StyledContainer>
  )
}
