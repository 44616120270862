import { Fragment } from "react"
import * as R from "ramda"
import ReactSlider from "react-slider"
import styled from "styled-components"

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height 3rem;
  display: flex;
  align-items: center;
`
const Track = styled.div<{ $trackHeight?: number; $trackBackground?: any; $index?: any }>`
  ${(props) => `
    height: ${props.$trackHeight}px;
    background-color: ${props.$trackBackground};
    display: flex;
    align-items: center;
    border-radius: 20px;
    border: inset 1px rgb(222, 222, 222);
    background: #f3f3f3;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.4), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  `}
`
const Knob = styled.div<{ $knobSize?: number; $shadow?: any; $index?: any }>`
  ${(props) => `
    width: ${props.$knobSize}px;
    height: ${props.$knobSize}px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 1px 6px 0 ${props.$shadow};
    cursor: pointer;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border: solid 1px #ababb7;
    // transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  `}
`

interface IYearSlider {
  min?: number
  max?: number
  value?: number
  knobSize?: number
  trackHeight?: number
  trackWidth?: number
  defaultValue?: number
  trackBackground?: any
  onChange?: (year: number) => number
}
const YearSlider: React.FC<IYearSlider> = ({
  min,
  max,
  value,
  knobSize = 50,
  trackHeight = 12,
  trackWidth = 300,
  defaultValue = 1970,
  trackBackground = "#DDD",
  onChange = (year: number) => Number(year),
  ...props
}) => {
  const handleChange = (e) => {
    onChange(e)
  }

  return (
    <StyledSlider
      min={min}
      max={max}
      value={value}
      {...props}
      onChange={(e) => handleChange(e)}
      // @ts-ignore
      defaultValue={defaultValue}
      renderTrack={(props, state) => (
        <Track
          $trackHeight={trackHeight}
          $trackBackground={trackBackground}
          $index={state.index}
          ref={(props.ref as unknown) as React.RefObject<HTMLDivElement>}
          {...R.omit(["ref", "as"], props)}
        />
      )}
      renderThumb={(props, state) => (
        <Knob
          $knobSize={knobSize}
          $index={state.index}
          ref={(props.ref as unknown) as React.RefObject<HTMLDivElement>}
          {...R.omit(["ref", "as"], props)}
        >
          <Fragment>{state.valueNow}</Fragment>
        </Knob>
      )}
    />
  )
}

export default YearSlider
