import styled from "styled-components"

const DiamondWrapper = styled.div`
  position: relative;
  max-width: var(--mobile-width);

  & .diamond {
    background-color: var(--color-primary);
    height: 400px;
    width: 400px;
    transform: rotate(45deg);
    border-radius: var(--border-radius-lg);
    // max-width: var(--mobile-width);
  }
`

export default function Diamond({ children, ...props }) {
  return (
    <DiamondWrapper {...props}>
      <div className="diamond" />
      {children}
    </DiamondWrapper>
  )
}
