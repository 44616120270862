// import * as R from "ramda"

import { min as d3Min, max as d3Max } from "d3-array"
import { scaleLinear as d3ScaleLinear, scaleBand as d3ScaleBand } from "d3-scale"
import { useChartDimensions } from "hooks/charts/useChartDimensions"
import { Axis, Bars, Chart } from "visualizations"
import { isDefined } from "utils/parseUtils"
import {
  // defaultNumericalAccessor,
  // defaultColorAccessor,
  defaultLabelAccessor,
  defaultGenericAccessor,
} from "utils/charts"
import styled from "styled-components"

import {
  ChartDimensions,
  // DataGeneric,
  // MetricAccessor,
  // GenericMetricAccessor,
} from "types/charts"

const HorizontalBarChartWrapper = styled.div`
  width: 100%;
  max-height: 600px;
`

const Title = styled.h2`
  margin: 0;
  grid-column: 1 / -1;
`
const Subtitle = styled.p`
  margin: 1rem 0 0 0;
  grid-column: 1 / -1;
`

interface IHorizontalBarChart<T> {
  data?: T[]
  title?: string
  subtitle?: string
  defaultXMin?: number
  defaultXMax?: number
  metricAccessor?: (d?: any, i?: any) => any // GenericMetricAccessor
  labelAccessor?: (d?: any, i?: any) => any // GenericMetricAccessor
  colorAccessor?: (d?: any, i?: any) => any // GenericMetricAccessor
  // metricAccessor?: typeof defaultNumericalAccessor
  // labelAccessor?: typeof defaultLabelAccessor
  // colorAccessor?: typeof defaultColorAccessor
  defaultDimensions?: ChartDimensions
  formatXAxisTick?: typeof defaultLabelAccessor
}
export default function HorizontalBarChart<T>({
  data,
  title,
  subtitle,
  defaultXMin,
  defaultXMax,
  // rawAccessor,
  metricAccessor = defaultGenericAccessor,
  labelAccessor = defaultGenericAccessor,
  colorAccessor = defaultGenericAccessor,
  defaultDimensions,
  formatXAxisTick,
}: IHorizontalBarChart<T>) {
  const [ref, dimensions] = useChartDimensions(defaultDimensions)

  const xMin = d3Min(data, (d) => metricAccessor(d) as number)
  const xMax = d3Max(data, (d) => metricAccessor(d) as number)

  const yAccessor = (d: T, i: number) => labelAccessor(d, i)

  // create scales
  const xScale = d3ScaleLinear()
    .domain([defaultXMin ? defaultXMin : xMin - 5, defaultXMax ? defaultXMax : xMax + 10])
    .range([0, dimensions.boundedWidth])

  const domain = data.map((d, i) => labelAccessor(d, String(i)))
  const yScale = d3ScaleBand()
    .domain(domain as string[])
    .rangeRound([dimensions.boundedHeight, 0])
    .paddingInner(0.4)
    .paddingOuter(0.4)
    .align(0.4)
    .round(true)

  const xAccessorScaled = (d, i) => 0
  const yAccessorScaled = (d, i) => yScale(String(yAccessor(d, i)))

  const heightAccessorScaled = (d, i) => yScale.bandwidth()
  const widthAccessorScaled = (d, i) => xScale(metricAccessor(d) as number)

  const labelXAccessorScaled = (d, i) => xAccessorScaled(d, i) + 10
  const labelYAccessorScaled = (d, i) => yAccessorScaled(d, i) + heightAccessorScaled(d, i) * 0.7

  const renderGridLines = () => {
    return xScale
      .ticks()
      .map((tick, i) => (
        <line
          key={i}
          x1={xScale(tick)}
          x2={xScale(tick)}
          y1={0}
          y2={dimensions.boundedHeight - 10}
          stroke="rgb(122, 122, 122)"
          strokeWidth={0.5}
          strokeOpacity={0.5}
          strokeDasharray={`4 4`}
        />
      ))
  }

  return (
    <>
      {isDefined(title) && <Title>{title}</Title>}
      {isDefined(subtitle) && <Subtitle>{subtitle}</Subtitle>}
      <HorizontalBarChartWrapper ref={ref}>
        <Chart noGTransform dimensions={dimensions}>
          <g transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}>
            <Axis dimensions={dimensions} dimension="x" scale={xScale} formatTick={formatXAxisTick} slide={-1} />
            <line x1={0} x2={0} y1={0} y2={dimensions.boundedHeight - 10} stroke="black" strokeWidth={0.75} />
            <line
              x1={0}
              x2={dimensions.boundedWidth + 30}
              y1={dimensions.boundedHeight - 10}
              y2={dimensions.boundedHeight - 10}
              stroke="black"
              strokeWidth={0.75}
            />
            {renderGridLines()}
            <Bars
              data={data}
              radius={2}
              keyAccessor={labelAccessor}
              xAccessor={xAccessorScaled}
              yAccessor={yAccessorScaled}
              colorAccessor={colorAccessor}
              widthAccessor={widthAccessorScaled}
              heightAccessor={heightAccessorScaled}
            />
            {/* <Axis dimension="y" scale={yScale} formatTick={formatYAxisTick} /> */}
            {data.map((d, i) => (
              <text
                className="x-label"
                key={i}
                x={labelXAccessorScaled(d, i)}
                y={labelYAccessorScaled(d, i)}
                fill={"white"}
              >
                {labelAccessor(d, i)}
              </text>
            ))}
          </g>
          {/* <Axis dimension="x" scale={xScale} formatTick={formatXAxisTick} /> */}
        </Chart>
      </HorizontalBarChartWrapper>
    </>
  )
}
