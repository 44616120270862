export const sinebow = [
  "#ff4040",
  "#ff423d",
  "#ff453a",
  "#ff4838",
  "#fe4b35",
  "#fe4e33",
  "#fe5130",
  "#fd542e",
  "#fd572b",
  "#fc5a29",
  "#fb5d27",
  "#fa6025",
  "#f96322",
  "#f96620",
  "#f7691e",
  "#f66c1c",
  "#f56f1a",
  "#f47218",
  "#f37517",
  "#f17815",
  "#f07c13",
  "#ee7f11",
  "#ed8210",
  "#eb850e",
  "#e9880d",
  "#e88b0c",
  "#e68e0a",
  "#e49209",
  "#e29508",
  "#e09807",
  "#de9b06",
  "#dc9e05",
  "#d9a104",
  "#d7a403",
  "#d5a703",
  "#d2aa02",
  "#d0ad02",
  "#ceb001",
  "#cbb301",
  "#c9b600",
  "#c6b800",
  "#c3bb00",
  "#c1be00",
  "#bec100",
  "#bbc300",
  "#b8c600",
  "#b6c900",
  "#b3cb01",
  "#b0ce01",
  "#add002",
  "#aad202",
  "#a7d503",
  "#a4d703",
  "#a1d904",
  "#9edc05",
  "#9bde06",
  "#98e007",
  "#95e208",
  "#92e409",
  "#8ee60a",
  "#8be80c",
  "#88e90d",
  "#85eb0e",
  "#82ed10",
  "#7fee11",
  "#7cf013",
  "#78f115",
  "#75f317",
  "#72f418",
  "#6ff51a",
  "#6cf61c",
  "#69f71e",
  "#66f920",
  "#63f922",
  "#60fa25",
  "#5dfb27",
  "#5afc29",
  "#57fd2b",
  "#54fd2e",
  "#51fe30",
  "#4efe33",
  "#4bfe35",
  "#48ff38",
  "#45ff3a",
  "#42ff3d",
  "#40ff40",
  "#3dff42",
  "#3aff45",
  "#38ff48",
  "#35fe4b",
  "#33fe4e",
  "#30fe51",
  "#2efd54",
  "#2bfd57",
  "#29fc5a",
  "#27fb5d",
  "#25fa60",
  "#22f963",
  "#20f966",
  "#1ef769",
  "#1cf66c",
  "#1af56f",
  "#18f472",
  "#17f375",
  "#15f178",
  "#13f07c",
  "#11ee7f",
  "#10ed82",
  "#0eeb85",
  "#0de988",
  "#0ce88b",
  "#0ae68e",
  "#09e492",
  "#08e295",
  "#07e098",
  "#06de9b",
  "#05dc9e",
  "#04d9a1",
  "#03d7a4",
  "#03d5a7",
  "#02d2aa",
  "#02d0ad",
  "#01ceb0",
  "#01cbb3",
  "#00c9b6",
  "#00c6b8",
  "#00c3bb",
  "#00c1be",
  "#00bec1",
  "#00bbc3",
  "#00b8c6",
  "#00b6c9",
  "#01b3cb",
  "#01b0ce",
  "#02add0",
  "#02aad2",
  "#03a7d5",
  "#03a4d7",
  "#04a1d9",
  "#059edc",
  "#069bde",
  "#0798e0",
  "#0895e2",
  "#0992e4",
  "#0a8ee6",
  "#0c8be8",
  "#0d88e9",
  "#0e85eb",
  "#1082ed",
  "#117fee",
  "#137cf0",
  "#1578f1",
  "#1775f3",
  "#1872f4",
  "#1a6ff5",
  "#1c6cf6",
  "#1e69f7",
  "#2066f9",
  "#2263f9",
  "#2560fa",
  "#275dfb",
  "#295afc",
  "#2b57fd",
  "#2e54fd",
  "#3051fe",
  "#334efe",
  "#354bfe",
  "#3848ff",
  "#3a45ff",
  "#3d42ff",
  "#4040ff",
  "#423dff",
  "#453aff",
  "#4838ff",
  "#4b35fe",
  "#4e33fe",
  "#5130fe",
  "#542efd",
  "#572bfd",
  "#5a29fc",
  "#5d27fb",
  "#6025fa",
  "#6322f9",
  "#6620f9",
  "#691ef7",
  "#6c1cf6",
  "#6f1af5",
  "#7218f4",
  "#7517f3",
  "#7815f1",
  "#7c13f0",
  "#7f11ee",
  "#8210ed",
  "#850eeb",
  "#880de9",
  "#8b0ce8",
  "#8e0ae6",
  "#9209e4",
  "#9508e2",
  "#9807e0",
  "#9b06de",
  "#9e05dc",
  "#a104d9",
  "#a403d7",
  "#a703d5",
  "#aa02d2",
  "#ad02d0",
  "#b001ce",
  "#b301cb",
  "#b600c9",
  "#b800c6",
  "#bb00c3",
  "#be00c1",
  "#c100be",
  "#c300bb",
  "#c600b8",
  "#c900b6",
  "#cb01b3",
  "#ce01b0",
  "#d002ad",
  "#d202aa",
  "#d503a7",
  "#d703a4",
  "#d904a1",
  "#dc059e",
  "#de069b",
  "#e00798",
  "#e20895",
  "#e40992",
  "#e60a8e",
  "#e80c8b",
  "#e90d88",
  "#eb0e85",
  "#ed1082",
  "#ee117f",
  "#f0137c",
  "#f11578",
  "#f31775",
  "#f41872",
  "#f51a6f",
  "#f61c6c",
  "#f71e69",
  "#f92066",
  "#f92263",
  "#fa2560",
  "#fb275d",
  "#fc295a",
  "#fd2b57",
  "#fd2e54",
  "#fe3051",
  "#fe334e",
  "#fe354b",
  "#ff3848",
  "#ff3a45",
  "#ff3d42",
  "#ff4040",
]
export const turbo = [
  "#23171b",
  "#271a28",
  "#2b1c33",
  "#2f1e3f",
  "#32204a",
  "#362354",
  "#39255f",
  "#3b2768",
  "#3e2a72",
  "#402c7b",
  "#422f83",
  "#44318b",
  "#453493",
  "#46369b",
  "#4839a2",
  "#493ca8",
  "#493eaf",
  "#4a41b5",
  "#4a44bb",
  "#4b46c0",
  "#4b49c5",
  "#4b4cca",
  "#4b4ecf",
  "#4b51d3",
  "#4a54d7",
  "#4a56db",
  "#4959de",
  "#495ce2",
  "#485fe5",
  "#4761e7",
  "#4664ea",
  "#4567ec",
  "#446aee",
  "#446df0",
  "#426ff2",
  "#4172f3",
  "#4075f5",
  "#3f78f6",
  "#3e7af7",
  "#3d7df7",
  "#3c80f8",
  "#3a83f9",
  "#3985f9",
  "#3888f9",
  "#378bf9",
  "#368df9",
  "#3590f8",
  "#3393f8",
  "#3295f7",
  "#3198f7",
  "#309bf6",
  "#2f9df5",
  "#2ea0f4",
  "#2da2f3",
  "#2ca5f1",
  "#2ba7f0",
  "#2aaaef",
  "#2aaced",
  "#29afec",
  "#28b1ea",
  "#28b4e8",
  "#27b6e6",
  "#27b8e5",
  "#26bbe3",
  "#26bde1",
  "#26bfdf",
  "#25c1dc",
  "#25c3da",
  "#25c6d8",
  "#25c8d6",
  "#25cad3",
  "#25ccd1",
  "#25cecf",
  "#26d0cc",
  "#26d2ca",
  "#26d4c8",
  "#27d6c5",
  "#27d8c3",
  "#28d9c0",
  "#29dbbe",
  "#29ddbb",
  "#2adfb8",
  "#2be0b6",
  "#2ce2b3",
  "#2de3b1",
  "#2ee5ae",
  "#30e6ac",
  "#31e8a9",
  "#32e9a6",
  "#34eba4",
  "#35eca1",
  "#37ed9f",
  "#39ef9c",
  "#3af09a",
  "#3cf197",
  "#3ef295",
  "#40f392",
  "#42f490",
  "#44f58d",
  "#46f68b",
  "#48f788",
  "#4af786",
  "#4df884",
  "#4ff981",
  "#51fa7f",
  "#54fa7d",
  "#56fb7a",
  "#59fb78",
  "#5cfc76",
  "#5efc74",
  "#61fd71",
  "#64fd6f",
  "#66fd6d",
  "#69fd6b",
  "#6cfd69",
  "#6ffe67",
  "#72fe65",
  "#75fe63",
  "#78fe61",
  "#7bfe5f",
  "#7efd5d",
  "#81fd5c",
  "#84fd5a",
  "#87fd58",
  "#8afc56",
  "#8dfc55",
  "#90fb53",
  "#93fb51",
  "#96fa50",
  "#99fa4e",
  "#9cf94d",
  "#9ff84b",
  "#a2f84a",
  "#a6f748",
  "#a9f647",
  "#acf546",
  "#aff444",
  "#b2f343",
  "#b5f242",
  "#b8f141",
  "#bbf03f",
  "#beef3e",
  "#c1ed3d",
  "#c3ec3c",
  "#c6eb3b",
  "#c9e93a",
  "#cce839",
  "#cfe738",
  "#d1e537",
  "#d4e336",
  "#d7e235",
  "#d9e034",
  "#dcdf33",
  "#dedd32",
  "#e0db32",
  "#e3d931",
  "#e5d730",
  "#e7d52f",
  "#e9d42f",
  "#ecd22e",
  "#eed02d",
  "#f0ce2c",
  "#f1cb2c",
  "#f3c92b",
  "#f5c72b",
  "#f7c52a",
  "#f8c329",
  "#fac029",
  "#fbbe28",
  "#fdbc28",
  "#feb927",
  "#ffb727",
  "#ffb526",
  "#ffb226",
  "#ffb025",
  "#ffad25",
  "#ffab24",
  "#ffa824",
  "#ffa623",
  "#ffa323",
  "#ffa022",
  "#ff9e22",
  "#ff9b21",
  "#ff9921",
  "#ff9621",
  "#ff9320",
  "#ff9020",
  "#ff8e1f",
  "#ff8b1f",
  "#ff881e",
  "#ff851e",
  "#ff831d",
  "#ff801d",
  "#ff7d1d",
  "#ff7a1c",
  "#ff781c",
  "#ff751b",
  "#ff721b",
  "#ff6f1a",
  "#fd6c1a",
  "#fc6a19",
  "#fa6719",
  "#f96418",
  "#f76118",
  "#f65f18",
  "#f45c17",
  "#f25916",
  "#f05716",
  "#ee5415",
  "#ec5115",
  "#ea4f14",
  "#e84c14",
  "#e64913",
  "#e44713",
  "#e24412",
  "#df4212",
  "#dd3f11",
  "#da3d10",
  "#d83a10",
  "#d5380f",
  "#d3360f",
  "#d0330e",
  "#ce310d",
  "#cb2f0d",
  "#c92d0c",
  "#c62a0b",
  "#c3280b",
  "#c1260a",
  "#be2409",
  "#bb2309",
  "#b92108",
  "#b61f07",
  "#b41d07",
  "#b11b06",
  "#af1a05",
  "#ac1805",
  "#aa1704",
  "#a81604",
  "#a51403",
  "#a31302",
  "#a11202",
  "#9f1101",
  "#9d1000",
  "#9b0f00",
  "#9a0e00",
  "#980e00",
  "#960d00",
  "#950c00",
  "#940c00",
  "#930c00",
  "#920c00",
  "#910b00",
  "#910c00",
  "#900c00",
  "#900c00",
  "#900c00",
]
