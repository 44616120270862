import styled from "styled-components"

const TooSmallWarningWrapper = styled.div`
  display: none;

  @media screen and (max-width: 390px) {
    display: flex;
    margin-bottom: 1.5rem;
  }
`

type TooSmallWarningProps = { text?: string | React.ReactNode }
export default function TooSmallWarning({
  text = <p>Screen is too small to see visualizations. Use a desktop for the full effect!</p>,
}: TooSmallWarningProps) {
  return (
    <TooSmallWarningWrapper>
      <>{text}</>
    </TooSmallWarningWrapper>
  )
}
