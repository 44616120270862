import { arc as d3Arc, line as d3Line } from "d3-shape"
import { scaleLinear as d3ScaleLinear } from "d3-scale"
import { Chart } from "components"
import { useChartDimensions } from "hooks/charts/useChartDimensions"
import styled from "styled-components"
import { ChartDimensions } from "types/charts"

const HalfCircleGaugeWrapper = styled.div``

interface IHalfCircleGauge {
  value: number
  title?: string
  colorSchema?: any[]
  defaultDimensions?: ChartDimensions
  min?: number
  max?: number
  innerRadius?: number
  outerRadius?: number
  className?: string
}
export default function HalfCircleGauge({
  value,
  title,
  colorSchema,
  defaultDimensions = { height: 200, width: 300 },
  min = 0,
  max = 100,
  innerRadius = 0.65,
  outerRadius = 1,
  className = "",
}: IHalfCircleGauge) {
  const [ref, dimensions] = useChartDimensions(defaultDimensions)
  const backgroundArc = d3Arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)(null)

  const percentScale = d3ScaleLinear().domain([min, max]).range([0, 1])
  const percent = percentScale(value)

  const angleScale = d3ScaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true)

  const angle = angleScale(percent)

  const filledArc = d3Arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(-Math.PI / 2)
    .endAngle(angle)(null)

  const colorScale = d3ScaleLinear().domain([0, 1]).range(colorSchema)
  const gradientSteps = colorScale.ticks(10).map((value) => colorScale(value))

  // line generator to make markers on gauge
  const lineGenerator = d3Line()
    .x((d) => d[0])
    .y((d) => d[1])

  const bottomMiddle: [number, number] = [0, 0]
  const bottomLeft: [number, number] = [-1.1, 0]
  const lineData: [number, number][] = [bottomMiddle, bottomLeft]

  return (
    <HalfCircleGaugeWrapper className={className} ref={ref}>
      <Chart noGTransform={true} viewBox={[-1, -1, 2, 1].join(" ")} dimensions={dimensions}>
        <defs>
          <linearGradient id={`Gauge__gradient-${title}`} gradientUnits="userSpaceOnUse" x1="-1" x2="1" y2="0">
            {gradientSteps.map((color, index) => (
              <stop
                key={color}
                stopColor={(color as unknown) as string}
                offset={`${index / (gradientSteps.length - 1)}`}
              />
            ))}
          </linearGradient>
        </defs>
        <path d={backgroundArc} fill={"rgb(232, 236, 254)"} />
        <path d={filledArc} fill={`url(#Gauge__gradient-${title})`} />

        <path
          d={lineGenerator(lineData)}
          transform={`rotate(${180 * percent})`}
          stroke={"rgba(40, 50, 90, 0.6)"}
          fill={"white"}
          strokeWidth={0.05}
        />
        <circle cx={0} cy={0} r={0.1} fill={[...colorSchema].pop()} />
      </Chart>
    </HalfCircleGaugeWrapper>
  )
}
