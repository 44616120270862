import { chunkArray } from "utils/arrays"
import styled from "styled-components"
import { randomLcg as d3RandomLcg } from "d3-random"
import { shuffler as d3Shuffler } from "d3-array"
import {
  // turbo,
  sinebow,
} from "utils/colors"

const seed = 0.1738
const source = d3RandomLcg(seed * 0.63)
const shuffle = d3Shuffler(source)

const StyledSVG = styled.svg`
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
`
const StyledG = styled.g`
  transform-origin: 50% 50%;
  transform: translateX(200px) translateY(-120px) rotate(45deg);
`

// const turboColors = turbo.map((color, i) => ({ color, i }))
const unwantedColors = ["#a1d904", "#33fe4e"]
const replacementColors = {
  "#a1d904": "rgb(55, 222, 213)",
  "#33fe4e": "rgb(48, 0, 138)",
}
const sinebowColors = sinebow.map((color, i) => ({ color, i }))
const colors = chunkArray(shuffle(sinebowColors.slice()), 4).slice(4, 12)
const yShifts = [-10, -15, -5, 10, 5, -7.5, -10, -15, -30]

export default function DiagonalColoredRects({ brickHeight = 90, brickWidth = 50, className = "" }) {
  return (
    <StyledSVG width={500} height={500} viewBox={"0 0 500 500"} className={className}>
      <StyledG>
        {colors.map((group, x) =>
          group.map((brick, y) => (
            <rect
              key={`brick-${x}-${y}`}
              fill={unwantedColors.includes(brick.color) ? replacementColors[brick.color] : brick.color}
              x={x * brickWidth}
              y={y * brickHeight + yShifts[x] * 4}
              height={brickHeight}
              width={brickWidth}
            />
          ))
        )}
      </StyledG>
    </StyledSVG>
  )
}
