import { useState } from "react"
import { pie as d3Pie, arc as d3Arc, Arc, DefaultArcObject } from "d3-shape"
import { Chart } from "components"
import { useChartDimensions } from "hooks/charts/useChartDimensions"
import styled from "styled-components"
// import { PieArcDatum } from "@visx/shape/lib/shapes/Pie"
import { ChartDimensions } from "types/charts"
// import { DefaultArcObject } from "d3"

const DonutChartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & path {
    transition: all 0.2s ease;
  }

  & .donut-chart-legend {
    display: flex;
    flex-direction: column;

    & .donut-chart-legend-item {
      display: flex;
      align-items: center;

      & .donut-chart-legend-metric {
        margin-left: 1rem;
        font-size: 1.2rem;
      }

      & .donut-chart-legend-label {
        margin-left: 0.5rem;
        font-size: 1.2rem;
      }
    }
  }
`

const LegendBar = styled.span<{ $color }>`
  width: 40px;
  height: 20px;
  background: ${(props) => props.$color};
  border: solid 1px black;
  cursor: pointer;
`

const getTransformForPath = (d: any, i: number, arc: any, keyAccessor: any, highlighted?: string) => {
  if (highlighted !== keyAccessor(d.data)) return `translate(0, 0)`

  return `translate(${arc.centroid(d).map((d: any) => d * 0.1)})`
}

interface IDonutChart<T> {
  data: T[]
  groupOrder: any[]
  keyAccessor: (d?: any, i?: any) => string
  colorMapping: Record<string, any>
  metricAccessor: (d?: any, i?: any) => any
  formatMetric: (d?: any, i?: any) => string
  // labelFontSize?: number
  defaultDims?: ChartDimensions
  padAngle?: number
  innerRadiusPercentSize?: number
}
export default function DonutChart<T>({
  data,
  groupOrder,
  keyAccessor,
  colorMapping,
  metricAccessor,
  formatMetric,
  // labelFontSize = 108,
  // defaultDims = { height: 400, width: 400 },
  defaultDims = { height: 400, width: 500, marginLeft: 100, marginRight: 100 },
  padAngle = 0.005,
  innerRadiusPercentSize = 0.7,
}: // title,
IDonutChart<T>) {
  const [highlighted, setHighlighted] = useState<string | null>(null)
  const [ref, dimensions] = useChartDimensions(defaultDims)
  // const [ref, dimensions] = useChartDimensions({ ...defaultDims, height: defaultDims.height + groupOrder.length * 20 })
  const colorAccessor = (d: T) => colorMapping[keyAccessor(d)]

  // construct shapes and scales
  const arcGenerator = d3Pie()
    .padAngle(padAngle)
    .value((d) => metricAccessor(d))
    .sort((a, b) => groupOrder.indexOf(keyAccessor(b)) - groupOrder.indexOf(keyAccessor(a)))
  const arcs = arcGenerator(data as any[])

  const radius = dimensions.boundedWidth * 0.5
  const arc = d3Arc()
    .innerRadius(radius * innerRadiusPercentSize)
    .outerRadius(radius)

  const renderLabel = () => (
    <div className="donut-chart-legend">
      {arcs
        .sort((a, b) => metricAccessor(b.data) - metricAccessor(a.data))
        .map((d) => (
          <div className="donut-chart-legend-item" key={keyAccessor(d.data)}>
            <LegendBar
              $color={colorAccessor(d.data as any)}
              className="donut-chart-legend-bar"
              onMouseEnter={() => setHighlighted(keyAccessor(d.data))}
              onMouseLeave={() => setHighlighted(null)}
            />
            <span className="donut-chart-legend-metric">{formatMetric(metricAccessor(d.data))}</span>
            <span className="donut-chart-legend-label">{keyAccessor(d.data)}</span>
          </div>
        ))}
    </div>
  )

  return (
    <DonutChartWrapper ref={ref}>
      <Chart fullWidth noGTransform dimensions={dimensions}>
        <g transform={`translate(${radius + dimensions.marginLeft}, ${radius + dimensions.marginTop})`}>
          {arcs.map((d: any, i) => (
            <path
              key={keyAccessor(d.data)}
              d={arc(d)}
              fill={colorAccessor(d.data)}
              stroke={"white"}
              transform={getTransformForPath(d, i, arc, keyAccessor, highlighted)}
              strokeWidth={2}
            />
          ))}
        </g>
      </Chart>
      {renderLabel()}
    </DonutChartWrapper>
  )
}
