import { useMemo } from "react"
import * as R from "ramda"
import { ResizeObserver } from "@juggle/resize-observer"
import { useTooltip, useTooltipInPortal } from "@visx/tooltip"
import { localPoint } from "@visx/event"
import { ParentSize } from "@visx/responsive"
// import { getMapFeatures as getFeatures } from "utils/chartUtils"
import { GeoPermissibleObjects } from "@visx/geo/lib/types"
import { ScaleLinear } from "d3"
import { Spinner } from "components"
import { USAMap } from "visualizations"

import {
  mapDataToState as defaultMapDataToState,
  getStateColor as defaultGetStateColor,
  getMapFeatures as getFeatures,
} from "utils/charts"

import styled from "styled-components"
import { MetricAccessor, GenericMetricAccessor, StateAccessor } from "types/charts"

const ResponsiveUSAMapWrapper = styled.div<{ $chartWidth?: number; $chartHeight?: number | string }>`
  width: 100%;
  width: ${(props) => props.$chartWidth || "100%"};
  max-width: 100vw;
  max-height: 1200px;
  height: ${(props) =>
    typeof props.$chartHeight === "string" ? props.$chartHeight : `${props.$chartHeight || 1200}px`};
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  & .map-state-path {
    fill: #ababb7;
    cursor: pointer;

    &:hover {
      fill: cornflowerblue;
    }
  }

  & .legend-wrapper {
    position: absolute;
    z-index: var(--z-index-input);
    top: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;

    & > h3 {
      margin-bottom: 0.1rem;
    }

    & > h4 {
      margin-bottom: 0.4rem;
    }

    & .legend-bar {
      display: flex;
      flex-direction: row;
    }

    & .legend-item {
      display: flex;
      flex-direction: column;
    }
  }
`

const background = "#F9F7E8"

const parseGeographies = (features: GeoPermissibleObjects[]) =>
  R.filter((feature: any) => feature?.properties["adm0_a3"] === "USA", features)

const DefaultTooltipContentComponent = ({ tooltipData, columnTitle }) => (
  <>
    <h3>Insufficient Data</h3>
  </>
)

const abbrevAccessor = (d: Record<string, number | string>, i: number | string): string => d.abbrev as string

interface ResponsiveUSAMapProps {
  title?: string
  geojson: Record<string, any>
  otherTitle?: string
  isLoading?: boolean
  colorScale: ScaleLinear<any, any, never>
  byStateData: Record<string, any>
  getStateColor?: (
    geo: any,
    metricAccessor: (d?: any, i?: any) => any,
    byStateData: Record<string, any>,
    colorScale: ScaleLinear<any, any, never>
  ) => string
  stateBorderColor?: string
  metricAccessor: (d?: any, i?: any) => any // GenericMetricAccessor | StateAccessor
  labelAccessor?: (d?: any, i?: any) => any // GenericMetricAccessor | StateAccessor
  // valueAccessor: MetricAccessor
  // metricAccessor: Accessor
  extractDataForStateAccessor?: (data?: any, stateName?: string) => any
  stateBarChartMetricAccessor?: (d?: any, i?: any) => any // GenericMetricAccessor | StateAccessor
  stateBarChartLabelAccessor?: (d?: any, i?: any) => any
  mapDataToState?: (
    geo: Record<string, any>,
    metricAccessor: (d?: any, i?: any) => any, // GenericMetricAccessor,
    byStateData: Record<string, any>
  ) => any
  chartWidth?: number
  chartHeight?: number | string
  defaultCenterX?: number
  defaultCenterY?: number
  shouldDisplayTooltip?: boolean
  showLegend?: boolean
  showBarChart?: boolean
  formatLegendMetric?: (d: any, i?: any) => string
  formatBarChartMetric?: (d: any, i?: any) => string
  // renderTooltipContent?: (d: any) => JSX.Element
  TooltipContentComponent?: typeof DefaultTooltipContentComponent
}
export default function ResponsiveUSAMap({
  title,
  geojson,
  isLoading,
  otherTitle,
  colorScale,
  byStateData,
  getStateColor = defaultGetStateColor,
  stateBorderColor = background,
  metricAccessor,
  labelAccessor = abbrevAccessor,
  extractDataForStateAccessor,
  stateBarChartMetricAccessor,
  stateBarChartLabelAccessor,
  mapDataToState = defaultMapDataToState,
  chartWidth,
  chartHeight = "100%",
  defaultCenterX,
  defaultCenterY,
  shouldDisplayTooltip = true,
  showLegend = true,
  showBarChart = true,
  formatLegendMetric = (d, i) => d,
  formatBarChartMetric = (d, i) => d,
  TooltipContentComponent = DefaultTooltipContentComponent,
  ...props
}: ResponsiveUSAMapProps) {
  const features = useMemo(() => getFeatures(geojson, parseGeographies), [geojson, parseGeographies])

  const stateMetricAccessor =
    typeof extractDataForStateAccessor === "function" ? extractDataForStateAccessor : metricAccessor

  // console.log({ features })

  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
    polyfill: ResizeObserver,
  })

  const handleOnMouseEnter = (event: any, geo: Record<string, any>) => {
    const coords = localPoint(event.target.ownerSVGElement, event)

    const tooltipData = mapDataToState(geo, stateMetricAccessor as GenericMetricAccessor, byStateData)

    if (shouldDisplayTooltip) {
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: { ...tooltipData, x: coords.x, y: coords.y, isShowing: tooltipOpen },
      })
    }
  }

  const handleOnMouseLeave = () => {
    hideTooltip()
  }

  // console.log({ isLoading })

  return (
    <ResponsiveUSAMapWrapper $chartWidth={chartWidth} $chartHeight={chartHeight} {...props}>
      <ParentSize>
        {({ width }) => (
          <>
            {isLoading ? (
              <Spinner rotateColors size="lg" />
            ) : (
              <USAMap
                title={title}
                features={features}
                defaultCenterX={defaultCenterX}
                defaultCenterY={defaultCenterY}
                otherTitle={otherTitle}
                formatLegendMetric={formatLegendMetric}
                formatBarChartMetric={formatBarChartMetric}
                stateBorderColor={stateBorderColor}
                ref={containerRef}
                data={byStateData}
                width={chartWidth || width}
                height={typeof chartHeight === "string" ? 900 : chartHeight || 900}
                showLegend={showLegend}
                showBarChart={showBarChart}
                stateBarChartMetricAccessor={stateBarChartMetricAccessor}
                stateBarChartLabelAccessor={stateBarChartLabelAccessor}
                labelAccessor={labelAccessor}
                colorScale={colorScale}
                handleOnMouseEnter={handleOnMouseEnter}
                handleOnMouseLeave={handleOnMouseLeave}
                getStateColor={(geo) =>
                  getStateColor(geo, stateMetricAccessor as GenericMetricAccessor, byStateData, colorScale)
                }
              />
            )}
          </>
        )}
      </ParentSize>

      {tooltipOpen && (
        <TooltipInPortal
          key={String(Math.random()) + new Date().toString()} // needed for bounds to update correctly
          left={tooltipLeft}
          top={tooltipTop}
        >
          <TooltipContentComponent tooltipData={tooltipData} columnTitle={tooltipData} />
          {/* {renderTooltipContent(tooltipData)} */}
        </TooltipInPortal>
      )}
    </ResponsiveUSAMapWrapper>
  )
}
