import { useState, Fragment } from "react"
import { min as d3Min, max as d3Max } from "d3-array"
import { scaleTime as d3ScaleTime, scaleLinear as d3ScaleLinear } from "d3-scale"
import { useChartDimensions } from "hooks/charts/useChartDimensions"
import { Axis, Chart, Circles, Legend, Line, Tooltip } from "visualizations"
import { isDefined } from "utils/parseUtils"
import {
  // useChartDimensions,
  // useUniqueId,
  callAccessor,
  formatWithCommas,
  formatYear,
} from "utils/charts"

import styled from "styled-components"
import { ChartDimensions, GenericMetricAccessor, DataAccessorSet } from "types/charts"

const ChartWrapper = styled.div<{ $flexGrow?: number }>`
  height: 700px;
  width: 100%;
  max-width: var(--mobile-width);
  // width: calc(100% + 1rem);
  margin-bottom: 2rem;
  grid-column: 1 / -1;

  background: white;
  padding: 0.6rem 1rem;
  margin: 0.5rem;
  position: relative;
  ${(props) => (props.$flexGrow ? `flex: ${props.$flexGrow};` : null)}
  flex: 3 1 auto;

  /* Make sure circles are clickable above the line charts */
  & circle {
    position: relative;
    z-index: var(--z-index-small);
  }
`

const Title = styled.h2`
  margin: 0;
  grid-column: 1 / -1;
`
const Subtitle = styled.p`
  margin: 1rem 0 0 0;
  grid-column: 1 / -1;
`

const TooltipContentWrapper = styled.div`
  max-width: 350px;

  & h4 {
    margin-bottom: 1rem;
    font-size: 16px;
  }
  & span {
    font-weight: bold;
  }
`
const TooltipMessage = styled.p`
  margin-bottom: 1rem;
  font-size: 1rem;
`
const TooltipBar = styled.div`
  height: 20px;
  width: 300px;
  border: solid 1px #a7a7ae;
  margin-left: 25px;
`
const TooltipBarInner = styled.div<{ $width?: number | string; $bg: any }>`
  margin-left: 1px;
  margin-top: 1px;
  margin-right: 1px;
  height: 16px;
  width: ${(props) => props.$width}%;
  background: ${(props) => props.$bg};
`

interface ITooltipContentComponent {
  tooltipData: Record<string, any>
  accessors: Record<string, ((d?: any, i?: any) => any) | string>
  // xAccessor: (d?: any, i?: any) => any
  // yAccessor: (d?: any, i?: any) => any
  formatDate: (d?: any, i?: any) => string
  formatTooltipMetric: (d?: any, i?: any) => string
  tooltipValue?: any
  tooltipMaxValue?: any
}
const DefaultTooltipContentComponent: React.FC<ITooltipContentComponent> = ({
  tooltipData,
  accessors,
  formatDate = formatYear,
  formatTooltipMetric,
  tooltipValue,
}) => {
  if (!accessors) return null

  const defaultContent = (
    <TooltipContentWrapper>
      <h4>Insufficent data available.</h4>
    </TooltipContentWrapper>
  )

  if (!tooltipData) return defaultContent

  const xAccessor = typeof accessors.xAccessor === "function" ? accessors.xAccessor : (d?: any, i?: any) => d.x

  try {
    const date = formatDate(xAccessor(tooltipData))
    const metric = formatTooltipMetric(xAccessor(tooltipData))

    if (!date || !metric) {
      return defaultContent
    }
  } catch (err) {
    return defaultContent
  }

  return (
    <TooltipContentWrapper>
      <h4>{accessors.label}</h4>

      <TooltipMessage>
        <span>{formatTooltipMetric(tooltipValue)}</span> of teachers with {(accessors.label as string).toLowerCase()}{" "}
        experience
      </TooltipMessage>

      <small>
        <span>
          {formatTooltipMetric(tooltipValue)} of the teaching workforce in {` `} {formatYear(xAccessor(tooltipData))}
        </span>
      </small>
      <TooltipBar>
        <TooltipBarInner $width={tooltipValue} $bg={accessors.color} />
      </TooltipBar>
    </TooltipContentWrapper>
  )
}

interface IMultiLineChart<T> {
  data?: T[]
  title?: string
  subtitle?: string
  dataAccessors?: DataAccessorSet[] // Record<string, GenericMetricAccessor>[]
  defaultDimensions?: ChartDimensions
  formatYAxisTick?: typeof formatWithCommas
  formatXAxisTick?: typeof formatYear
  defaultYMin?: number | Date
  defaultYMax?: number | Date
  lineStrokeWidth?: number
  circleSize?: number
  circleStrokeWeight?: number
  formatTooltipMetric?: (d: any) => string
  includeArea?: boolean
  includeCircles?: boolean
  includeTooltip?: boolean
  legendXPos?: number
  legendYPos?: number
  TooltipContentComponent?: typeof DefaultTooltipContentComponent
}
export default function MultiLineChart<T>({
  data,
  title,
  subtitle,
  dataAccessors,
  defaultDimensions = {},
  formatYAxisTick = formatWithCommas,
  formatXAxisTick = formatYear,
  defaultYMin,
  defaultYMax,
  lineStrokeWidth = 3,
  circleSize = 5,
  circleStrokeWeight = 3,
  formatTooltipMetric,
  includeArea = true,
  includeCircles = true,
  includeTooltip = true,
  legendXPos = 100,
  legendYPos = -20,
  TooltipContentComponent = DefaultTooltipContentComponent,
  ...props
}: IMultiLineChart<T>) {
  const [ref, dimensions] = useChartDimensions(defaultDimensions)

  const [tooltipShowing, setTooltipShowing] = useState(false)
  const [tooltipShift, setTooltipShift] = useState(null)
  const [tooltipLocation, setTooltipLocation] = useState({ x: 0, y: 0 })
  const [tooltipData, setTooltipData] = useState({})
  const [tooltipValue, setTooltipValue] = useState(null)
  const [tooltipMaxValue, setTooltipMaxValue] = useState(null)
  // const [tooltipRawValue, setTooltipRawValue] = useState(null)
  // const [tooltipMaxRawValue, setTooltipMaxRawValue] = useState(null)
  const [tooltipAccessors, setTooltipAccessors] = useState(null)

  if (!data) return <span ref={ref} />

  const xValues = dataAccessors.map(({ xAccessor }) => data.map((d, i) => xAccessor(d, i))).flat()
  const yValues = dataAccessors.map(({ yAccessor }) => data.map((d, i) => yAccessor(d, i))).flat()

  const xScale = d3ScaleTime()
    // .domain(d3Extent(data, xAccessor))
    .domain([d3Min(xValues as Date[], (d) => d), d3Max(xValues as Date[], (d) => d)])
    .range([0, dimensions.boundedWidth])

  const yScale = d3ScaleLinear()
    .domain([
      isDefined(defaultYMin) ? defaultYMin : d3Min(yValues as number[], (d) => d),
      isDefined(defaultYMax) ? defaultYMax : d3Max(yValues as number[], (d) => d),
    ])
    .range([dimensions.boundedHeight, 0])
    .nice()

  const handleOnHover = (d, accessors, i) => {
    const x = xScale(callAccessor(accessors.xAccessor, d, i)) + dimensions.marginLeft + 15
    const y = yScale(callAccessor(accessors.yAccessor, d, i)) + dimensions.marginTop

    const unstackedValue = accessors.yAccessor(d)
    const maxValue = 100
    // const rawValue = accessors.rawMetricAccessor(d)
    // const maxRawValue = accessors.maxAccessor(d)

    setTooltipData(d)
    setTooltipAccessors(accessors)
    setTooltipValue(unstackedValue)
    setTooltipMaxValue(maxValue)
    // if (rawValue) {
    //   setTooltipRawValue(rawValue)
    //   setTooltipMaxRawValue(maxRawValue)
    // }

    if (x > dimensions.boundedWidth - dimensions.boundedWidth * 0.1) {
      setTooltipShift("farRight")
    } else if (x < dimensions.boundedWidth * 0.3) {
      setTooltipShift("farLeft")
    } else {
      setTooltipShift(null)
    }

    setTooltipLocation({ x, y })

    setTooltipShowing(true)
  }

  const handleOnLeave = () => {
    setTooltipLocation({ x: 0, y: 0 })
    setTooltipShowing(false)
    setTooltipData(null)
    setTooltipAccessors(null)
    setTooltipValue(null)
    setTooltipMaxValue(null)
    // setTooltipRawValue(null)
    // setTooltipMaxRawValue(null)
  }

  return (
    <>
      {isDefined(title) && <Title>{title}</Title>}
      {isDefined(subtitle) && <Subtitle>{subtitle}</Subtitle>}
      <ChartWrapper ref={ref} {...props} className="MultiLine_Chart">
        <Tooltip
          x={tooltipLocation.x}
          y={tooltipLocation.y}
          isFarRight={tooltipShift === "farRight"}
          isFarLeft={tooltipShift === "farLeft"}
          isShowing={includeTooltip && tooltipShowing}
        >
          <TooltipContentComponent
            tooltipData={tooltipData}
            accessors={tooltipAccessors}
            formatDate={(d) => String(d)}
            formatTooltipMetric={formatTooltipMetric}
            tooltipValue={tooltipValue}
            tooltipMaxValue={tooltipMaxValue}
          />
        </Tooltip>

        <Chart fullWidth noGTransform dimensions={dimensions}>
          <g transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}>
            <Legend
              x={legendXPos}
              y={legendYPos}
              barXAccessor={10}
              barYAccessor={(d, i) => i * 25}
              barHeightAccessor={15}
              barWidthAccessor={60}
              data={dataAccessors.map((d, i) => ({ label: d.label, color: d.lineStrokeColor }))}
            />
            <Axis dimensions={dimensions} dimension="x" scale={xScale} formatTick={formatXAxisTick} />
            <Axis dimensions={dimensions} dimension="y" scale={yScale} formatTick={formatYAxisTick} />
            <line x1={0} x2={0} y1={0} y2={dimensions.boundedHeight} stroke="rgb(202, 202, 222)" strokeWidth={1} />
            <line
              x1={0}
              x2={dimensions.boundedWidth}
              y1={dimensions.boundedHeight}
              y2={dimensions.boundedHeight}
              stroke="rgb(202, 202, 222)"
              strokeWidth={1}
            />

            {dataAccessors.map((d, idx) => {
              const { xAccessor, yAccessor, keyAccessor, lineStrokeColor } = d

              return (
                <Line
                  key={`${keyAccessor(d, idx)}-${idx}`}
                  data={data}
                  type="line"
                  xAccessor={(d, i) => xScale(xAccessor(d, i) as number)}
                  yAccessor={(d, i) => yScale(yAccessor(d, i) as number)}
                  lineStrokeColor={lineStrokeColor}
                  lineStrokeWidth={lineStrokeWidth}
                />
              )
            })}

            {includeCircles
              ? dataAccessors.map((d, idx) => {
                  const { xAccessor, yAccessor, keyAccessor, circleColor, circleStrokeColor } = d

                  return (
                    <Circles
                      key={`${keyAccessor(d, idx)}-${idx}`}
                      data={data}
                      radius={circleSize}
                      keyAccessor={keyAccessor}
                      xAccessor={(d, i) => xScale(xAccessor(d, i) as number)}
                      yAccessor={(d, i) => yScale(yAccessor(d, i) as number)}
                      circleColor={circleColor}
                      circleStroke={circleStrokeColor}
                      isHoverable={includeTooltip}
                      handleOnHover={(dv, i) => handleOnHover(dv, d, i)}
                      handleOnLeave={handleOnLeave}
                    />
                  )
                })
              : null}
          </g>
        </Chart>
      </ChartWrapper>
    </>
  )
}
